import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {
  Table,
  TableHeader,
  TableCell,
  TableFooter,
  TableContainer,
  Select,
  Input,
  Button as B,
  Card as C,
  CardBody,
  Pagination,
} from "@windmill/react-ui";

import ListGroup from "react-bootstrap/ListGroup";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./HeaderTopBar";
import SEO from "./SEO";
import HeaderOne from "./HeaderOne";
import Separator from "./Separator";
import SectionTitle from "./SectionTitle";
import SlpitOne from "./SlpitOne";
import SlipFour from "./SlipFour";
import Wrestlers from "./Wrestlers";
import Utility from "./Utility";
import RoadMap2 from "./RoadMap2";
import Team2 from "./Team2";
import CalltoActionFive from "./CalltoActionFive";
import Socials from "./Socials";
import CalltoActionTwo from "./CalltoActionTwo";
import Counter from "./Counter";
import Utility2 from "./Utility2";
import { BsDiscord } from "react-icons/bs";
import Footer from "./Footer";
import { useContext, useEffect, useRef, useState } from "react";
import {
  DateEvent,
  DateForB,
  HoldersCheck,
  NftList,
  TimeEvent,
  TimeSelect,
  Url,
  Verified,
  Wallet,
} from "../App";
import Axios from "axios";
import QRCode from "qrcode.react";
import { scopePollingDetectionStrategy } from "@solana/wallet-adapter-base";
import { Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import Stack from "@mui/material/Stack";
import Modal from "react-bootstrap/Modal";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DatePicker, Space } from "antd";
import {
  Table as T,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import Calendar from "react-calendar";
import Time from "./Time.js";

function Bookings() {
  const [verified, setVerified] = useContext(Verified);
  const [code, setCode] = useState("");
  const [url, setUrl] = useContext(Url);
  const [wallet, setWallet] = useContext(Wallet);
  const [status, setStatus] = useState();
  const [user, setUser] = useState([]);
  const [mintAddress, setMintAddress] = useState("");
  const [nftList, setNftList] = useContext(NftList);
  const [loading, setLoading] = useState(true);
  const [holdersCheck, setHoldersCheck] = useContext(HoldersCheck);
  const [startDate, setStartDate] = useState(new Date());
  const [arrDates, setArrDates] = useState([
    new Date(2022, 11, 4, 9, 0),
    new Date(2022, 11, 4, 11, 0),
    new Date(2022, 11, 4, 13, 0),
    new Date(2022, 11, 4, 15, 0),
    new Date(2022, 11, 4, 17, 0),
    new Date(2022, 11, 4, 19, 0),
    new Date(2022, 11, 4, 21, 0),
    new Date(2022, 11, 4, 23, 0),
  ]);
  const [email, setEmail] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [nftForB, setNftForB] = useState("");
  const [dateValue, setDateValue] = useState();
  const [emailCheck, setEmailCheck] = useState(false);
  const [imageCheck, setImageCheck] = useState(false);
  const [nftCheck, setNftCheck] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [emailBookings, setEmailBookings] = useState([]);
  const [imageUrlBookings, setImageUrlBookings] = useState([]);
  const [nftBookings, setNftBookings] = useState([]);
  const [message, setMessage] = useState([]);
  const [show, setShow] = useState(false);
  const [checkConnection, setCheckConnection] = useState([]);
  const [dateBookings, setDateBookings] = useState([]);
  const [dateCheck, setDateCheck] = useState(false);
  const [value, setValue] = useState();
  const [userBookings, setUserBookings] = useState([]);
  const [modCheck, setModCheck] = useState("");
  const [imgMod, setImgMod] = useState("");
  const [dateMod, setDateMod] = useState("");
  const [zStuff, setZStuff] = useState("10000");
  const [nftChange, setNftChange] = useState("");
  const [dateForB, setDateForB] = useContext(DateForB);
  const [showTime, setShowTime] = useState(false);
  const [timeSelect, setTimeSelect] = useContext(TimeSelect);
  const [timeEvent, setTimeEvent] = useContext(TimeEvent);
  const [dateEvent, setDateEvent] = useContext(DateEvent);
  const ref = useRef();

  useEffect(() => {
    if (timeSelect == true) {
      setStartDate(dateEvent + " " + timeEvent);
      console.log("time selected");
      console.log("this is time selected" + timeEvent);
      console.log("this is the date Selected" + dateEvent);
    }
  }, [timeSelect]);

  useEffect(() => {
    setTimeSelect(false);
  }, []);

  const handleClose = () => {
    setZStuff("10000");
    setNftChange("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  //dayjs
  const onChange = (value, dateString) => {
    setStartDate(dateString);
    console.log("This is the date string " + dateString);
  };

  const onOk = (value) => {
    setZStuff("10000");
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    var d = new Date(2023, 4, 15);
    var day = dayjs(d);
    return current && current > day.endOf("day");
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(0, 6),
    disabledMinutes: () => range(1, 60),
    disabledSeconds: () => [55, 56],
  });

  const getVerification = async () => {
    await Axios.post(url + "/api/getverification", {
      name: wallet,
    }).then((response) => {
      if (response.data.length > 0) {
        setCode(response.data[0].code);
        setStatus(response.data[0].verified);
        setUser(response.data);

        setLoading(false);
      } else {
        console.log("there is no code");
      }
    });
  };

  const updatePending = async () => {
    await Axios.post(url + "/api/updatepending", {
      nft: nftChange,
    }).then((response) => {
      if (response.data.message) {
        console.log("Cannot update");
      }
      handleClose();
    });
  };
  const updateDate = async () => {
    await Axios.post(url + "/api/updatedate", {
      dateTime: startDate,
      nft: nftChange,
      date: dateEvent,
      time: timeEvent,
    }).then((response) => {
      if (!response.data.message) {
        updatePending();
        console.log("pending update");
        setDateEvent("");
        setTimeEvent("");
        setStartDate("");
      } else {
        handleClose();
      }
    });
  };

  const updateDateImage = async () => {
    updateDate();
    updateImage();
  };

  const updateImage = async () => {
    await Axios.post(url + "/api/updateimage", {
      imageUrl: imgMod,
      nft: nftChange,
    }).then((response) => {
      if (!response.data.message) {
        updatePending();
        console.log("pending update");
      } else {
        handleClose();
      }
    });
  };

  const selectBookings = async () => {
    await Axios.post(url + "/api/selectbookings", {
      wallet: wallet,
    }).then((response) => {
      if (response.data.length > 0) {
        setUserBookings(response.data);

        setLoading(false);
      } else {
        console.log("there is no code");
      }
    });
  };

  useEffect(() => {
    selectBookings();
  }, []);

  const addBillboard = async () => {
    await Axios.post(url + "/api/addbillboard", {
      email: email,
      imageUrl: imageUrl,
      dateTime: startDate,
      nft: nftForB,
      wallet: wallet,
    }).then((response) => {
      console.log(response);
    });
  };
  //get all nfts
  /*useEffect(() => {
    console.log(user);
  }, [user]);*/
  useEffect(() => {
    const interval = setInterval(() => {
      getVerification();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (emailCheck == true) {
      setTimeout(() => {
        setEmailCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [emailCheck]);
  useEffect(() => {
    if (imageCheck == true) {
      setTimeout(() => {
        setImageCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [imageCheck]);

  useEffect(() => {
    if (dateCheck == true) {
      setTimeout(() => {
        setDateCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [dateCheck]);

  useEffect(() => {
    const interval = setInterval(() => {
      const a = async () =>
        await Axios.get(url + "/api/getbookings")
          .then((response, err) => {
            if (response.data.length > 0) {
              setEmailBookings(response.data?.map((a) => a.email));
              setImageUrlBookings(response.data?.map((a) => a.imageUrl));
              setNftBookings(response.data?.map((a) => a.nft));
              setDateBookings(response.data?.map((a) => a.dateTime));
              setCheckConnection([]);
            }
          })
          .catch((error) => {
            setCheckConnection([{ display: "none" }]);
          });
      a();
      selectBookings();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (verified != true) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  return (
    <>
      <SEO title="Fight Capital NFT" />
      <main className="page-wrapper">
        {/* Start Slider Area  */}
        <Modal
          show={show}
          onHide={handleClose}
          style={{ color: "black", zIndex: zStuff }}
        >
          <Modal.Header>
            <Modal.Title style={{ color: "black" }}>
              Please Modify Requested Details.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {modCheck == "image" ? (
                <Form.Group className="mb-3">
                  <Form.Label>Please Change Image Url</Form.Label>
                  <Form.Control
                    type="text"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    onChange={({ target }) => setImgMod(target.value)}
                  />
                  <Form.Text className="text-muted">
                    Please provide Link for Image Url.
                    <br />
                    Use this template to create your art as 576x256 pixels and
                    submit below.
                    <br />
                    <a href='https://i.ibb.co/NxgnDq9/Cocktail-NERD-NFT-Template-v3-2.png" alt="Cocktail-NERD-NFT-Template-v3-2'>
                      Template
                    </a>
                  </Form.Text>
                </Form.Group>
              ) : modCheck == "date" ? (
                dateCheck == false ? (
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <div>
                      <Calendar
                        onChange={setDateForB}
                        value={dateForB}
                        onClickDay={() => setShowTime(true)}
                      />
                    </div>
                    {dateForB.length > 0 ? (
                      <p>
                        <span>Start:</span>
                        {dateForB[0].toDateString()}
                        &nbsp; &nbsp;
                        <span>End:</span>
                        {dateForB[1].toDateString()}
                      </p>
                    ) : (
                      <p></p>
                    )}
                    <Time showTime={showTime} date={dateForB} />
                  </Form.Group>
                ) : (
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                    style={{ zIndex: "20000" }}
                  >
                    <Form.Label>Select Date and Time</Form.Label>

                    <div>
                      <Calendar
                        onChange={setDateForB}
                        value={dateForB}
                        onClickDay={() => setShowTime(true)}
                      />
                    </div>
                    {dateForB.length > 0 ? (
                      <p>
                        <span>Start:</span>
                        {dateForB[0].toDateString()}
                        &nbsp; &nbsp;
                        <span>End:</span>
                        {dateForB[1].toDateString()}
                      </p>
                    ) : (
                      <p></p>
                    )}
                    <Time showTime={showTime} date={dateForB} />
                    <Form.Text className="text-muted">
                      Date Not Available. Please Select A New Date.
                    </Form.Text>
                  </Form.Group>
                )
              ) : (
                modCheck == "date/image" && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Please Change Image Url</Form.Label>
                      <Form.Control
                        type="text"
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                        onChange={({ target }) => setImgMod(target.value)}
                      />
                      <Form.Text className="text-muted">
                        Please provide Link for Image Url.
                        <br />
                        Use this template to create your art as 576x256 pixels
                        and submit below.
                        <br />
                        <a href='https://i.ibb.co/NxgnDq9/Cocktail-NERD-NFT-Template-v3-2.png" alt="Cocktail-NERD-NFT-Template-v3-2'>
                          Template
                        </a>
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <div>
                        <Calendar
                          onChange={setDateForB}
                          value={dateForB}
                          onClickDay={() => setShowTime(true)}
                        />
                      </div>
                      {dateForB.length > 0 ? (
                        <p>
                          <span>Start:</span>
                          {dateForB[0].toDateString()}
                          &nbsp; &nbsp;
                          <span>End:</span>
                          {dateForB[1].toDateString()}
                        </p>
                      ) : (
                        <p></p>
                      )}
                      <Time showTime={showTime} date={dateForB} />
                    </Form.Group>
                  </>
                )
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {modCheck == "date" ? (
              <Button
                variant="primary"
                onClick={() => {
                  if (dateBookings.includes(startDate)) {
                    setDateCheck(true);
                    console.log("datecheck is true");
                  } else if (
                    startDate != "" &&
                    startDate != undefined &&
                    startDate != null
                  ) {
                    updateDate();
                  }
                }}
              >
                Submit Date
              </Button>
            ) : modCheck == "image" ? (
              <Button variant="primary" onClick={() => updateImage()}>
                Submit Image
              </Button>
            ) : (
              modCheck == "date/image" && (
                <Button variant="primary" onClick={() => updateDateImage()}>
                  Submit Date/Image
                </Button>
              )
            )}
          </Modal.Footer>
        </Modal>

        <div
          className="rwt-advance-tab-area rn-section-gap"
          style={{ padding: "1rem" }}
        >
          <div className="container">
            <div className="row mb--40" style={{ margin: 0 }}>
              <div className="col-lg-12" style={{ margin: 0 }}>
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Current Bookings"
                  style={{ margin: 0 }}
                />
              </div>
            </div>
            <div>
              {userBookings.length < 1 && loading == false ? (
                <h2 style={{ textAlign: "center" }}>No Bookings Found</h2>
              ) : (
                loading == true && (
                  <h2 style={{ textAlign: "center" }}>Loading...</h2>
                )
              )}
              <Row xs={1} md={2} className="g-4">
                {userBookings.map((a) => (
                  <Col>
                    <Card
                      border={
                        a.status == "pending"
                          ? "secondary"
                          : a.status == "Date" ||
                            a.status == "See Email" ||
                            a.status == "Email" ||
                            a.status == "Image" ||
                            a.status == "Date/Image"
                          ? "warning"
                          : a.status == "Approved" && "success"
                      }
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        margin: "1rem",
                        borderRadius: "2%",
                      }}
                      className="min-w-0 shadow-xs overflow-hidden bg-gray-800 mb-5"
                    >
                      <Card.Header>
                        <strong>Booking # {a.id}</strong>
                      </Card.Header>
                      <Card.Body style={{ padding: "1rem" }}>
                        <Card.Title style={{ margin: 0 }}>
                          NFT Address
                        </Card.Title>
                        <Card.Text style={{ marginBottom: "10px" }}>
                          <>{a.nft}</>
                        </Card.Text>

                        <Card.Title style={{ margin: 0 }}>Date/Time</Card.Title>
                        <Card.Text style={{ marginBottom: "10px" }}>
                          {a.dateTime}
                        </Card.Text>
                        <Card.Title style={{ margin: 0 }}>Image Url</Card.Title>
                        <Card.Text style={{ marginBottom: "10px" }}>
                          {a.imageUrl}
                        </Card.Text>
                        {a.status != "Approved" && (
                          <>
                            <Card.Title style={{ margin: 0 }}>
                              Additional Info
                            </Card.Title>
                            <Card.Text style={{ marginBottom: "10px" }}>
                              {a.addinfo != "false" ? a.addinfo : "N/A"}
                            </Card.Text>
                          </>
                        )}
                        <Card.Title
                          style={{ marginTop: 0, marginBottom: "10px" }}
                        >
                          Status
                        </Card.Title>
                        <Card.Text style={{ marginBottom: "10px" }}>
                          {a.status == "pending" ? (
                            <Button
                              variant="secondary"
                              style={{ width: "100%", height: "100px" }}
                              disabled
                            >
                              Pending
                            </Button>
                          ) : a.status == "See Email" || a.status == "Email" ? (
                            <Button
                              variant="info"
                              style={{ width: "100%", height: "100px" }}
                              disabled
                            >
                              See Email
                            </Button>
                          ) : a.status == "Date/Image" ? (
                            <Button
                              variant="warning"
                              onClick={() => {
                                setModCheck("date/image");
                                setNftChange(a.nft);
                                handleShow();
                              }}
                              style={{ width: "100%", height: "100px" }}
                            >
                              Change Date and Image Url
                            </Button>
                          ) : a.status == "Date" ? (
                            <Button
                              variant="warning"
                              onClick={() => {
                                setModCheck("date");
                                setNftChange(a.nft);
                                handleShow();
                              }}
                              style={{ width: "100%", height: "100px" }}
                            >
                              Change Date
                            </Button>
                          ) : a.status == "Approved" ? (
                            <Button
                              variant="success"
                              style={{ width: "100%", height: "100px" }}
                              disabled
                            >
                              Approved
                            </Button>
                          ) : (
                            a.status == "Image" && (
                              <Button
                                variant="warning"
                                onClick={() => {
                                  setModCheck("image");
                                  setNftChange(a.nft);
                                  handleShow();
                                }}
                                style={{ width: "100%", height: "100px" }}
                              >
                                Change Image Url
                              </Button>
                            )
                          )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
              {/*<T>
                <Thead>
                  <Tr>
                    <Th>NFT</Th>
                    <Th>Date/Time</Th>
                    <Th>Image URL</Th>
                    <Th>Status</Th>
                    <Th>Additional Info:</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userBookings.map((a) => (
                    <>
                      <Tr>
                        <Td>{a.nft}</Td>

                        <Td>{a.dateTime}</Td>
                        <Td>{a.imageUrl}</Td>
                        <Td>
                          {a.status == "pending" ? (
                            <Button
                              variant="secondary"
                              style={{ width: "100%" }}
                              disabled
                            >
                              Pending
                            </Button>
                          ) : a.status == "See Email" || a.status == "Email" ? (
                            <Button
                              variant="info"
                              style={{ width: "100%" }}
                              disabled
                            >
                              See Email
                            </Button>
                          ) : a.status == "Date/Image" ? (
                            <Button
                              variant="warning"
                              onClick={() => {
                                setModCheck("date/image");
                                setNftChange(a.nft);
                                handleShow();
                              }}
                              style={{ width: "100%" }}
                            >
                              Change Date and Image Url
                            </Button>
                          ) : a.status == "Date" ? (
                            <Button
                              variant="warning"
                              onClick={() => {
                                setModCheck("date");
                                setNftChange(a.nft);
                                handleShow();
                              }}
                              style={{ width: "100%" }}
                            >
                              Change Date
                            </Button>
                          ) : a.status == "Approved" ? (
                            <Button
                              variant="success"
                              style={{ width: "100%" }}
                              disabled
                            >
                              Approved
                            </Button>
                          ) : (
                            a.status == "Image" && (
                              <Button
                                variant="warning"
                                onClick={() => {
                                  setModCheck("image");
                                  setNftChange(a.nft);
                                  handleShow();
                                }}
                                style={{ width: "100%" }}
                              >
                                Change Image Url
                              </Button>
                            )
                          )}
                        </Td>
                        {a.addinfo == "false" ? (
                          <Td>N/A</Td>
                        ) : (
                          <Td>{a.addinfo}</Td>
                        )}
                      </Tr>
                    </>
                  ))}
                </Tbody>
                        </T>*/}
            </div>
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />

        <Socials />
        <Footer />

        {/* End Elements Area  */}
      </main>
    </>
  );
}

export default Bookings;
