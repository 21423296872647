// Import Css Here

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./HeaderTopBar";
import SEO from "./SEO";
import HeaderOne from "./HeaderOne";
import Separator from "./Separator";
import SectionTitle from "./SectionTitle";
import SlpitOne from "./SlpitOne";
import SlipFour from "./SlipFour";
import Wrestlers from "./Wrestlers";
import Utility from "./Utility";
import RoadMap2 from "./RoadMap2";
import Team2 from "./Team2";
import CalltoActionFive from "./CalltoActionFive";
import Socials from "./Socials";
import CalltoActionTwo from "./CalltoActionTwo";
import Counter from "./Counter";
import Utility2 from "./Utility2";
import { BsDiscord } from "react-icons/bs";
import Footer from "./Footer";
import { useContext, useEffect, useState } from "react";
import { Url, Verified, Wallet } from "../App";
import Axios from "axios";
import QRCode from "qrcode.react";
import ListGroup from "react-bootstrap/ListGroup";
import { scopePollingDetectionStrategy } from "@solana/wallet-adapter-base";

function Loading({ code, address, mint_address }) {
  const [data, setData] = useState("No result");
  const [resul, setResul] = useState("");
  const [verification, setVerification] = useState(false);
  const [verified, setVerified] = useContext(Verified);
  const [codes, setCodes] = useState(code);
  const [name, setName] = useState(address);
  const [url, setUrl] = useContext(Url);
  const [wallet, setWallet] = useContext(Wallet);
  const [status, setStatus] = useState();
  const [checker, setChecker] = useState([]);
  const [change, setChange] = useState(false);
  const [wait, setWait] = useState(false);
  const [lis, setLis] = useState([]);
  const [nums, setNums] = useState(0);

  const getVerification = async (a) => {
    await Axios.post(url + "/api/getverification2", {
      name: mint_address,
    }).then((response) => {
      if (response.data.length > 0) {
        setChecker(response.data[0]);
        //console.log(response.data[0]);
      } else {
        console.log("No data Found.");
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getVerification();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log("this is code");
    console.log(codes);
    console.log("this is address");
    console.log(name);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (verification == true) {
        setVerification(false);
      }
      if (resul != "") {
        setResul("");
      }
    }, 5000);
  }, [verification, resul]);

  useEffect(() => {
    if (wait == true) {
      setTimeout(() => setWait(false), 5000);
    }
  }, [wait]);

  const verify = async (a) => {
    await Axios.post(url + "/api/verify", {
      code: a,
    }).then((response) => {
      if (response.data.length > 0) {
        setVerification(true);
        setName(response.data[0].name);
        claimed(a);
        setWait(true);
        setLis([...lis, { name: response.data[0].name, result: "Pass" }]);
      } else {
        console.log(a);
        setVerification(false);
        setResul("Invalid Code");
        setLis([...lis, { name: "No user", result: "Invalid Code" }]);
      }
    });
  };

  const claimed = async (b) => {
    await Axios.post(url + "/api/claimed", {
      code: b,
    }).then((response) => {
      console.log("Response has been claimed");
    });
  };

  const changeClaim = async (b) => {
    await Axios.post(url + "/api/changeclaim", {
      name: mint_address,
    }).then((response) => {
      console.log("Response has been claimed");
      setChange(!change);
    });
  };

  useEffect(() => {
    let num = 0;
    const interval = setInterval(() => {
      if (num < 5) {
        num += 1;
        console.log(num);
      } else {
        setNums(num);
        console.log(num);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (nums == 5) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <>
      <SEO title="Fight Capital NFT" />
      <main className="page-wrapper">
        {/* Start Slider Area  */}

        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h1 className="display-two">
                    Loading...
                    <br />
                    {/*For{"  "}
                          <Typed
                            strings={["Freelancer.", "Developer.", "Startup."]}
                            typeSpeed={80}
                            backSpeed={5}
                            backDelay={1000}
                            loop
        />*/}
                  </h1>
                </div>
              </div>
              <div className="col-lg-4 order-1 order-lg-2">
                <div className="thumbnail">
                  <img src="./images/FC/955.png" alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Slider Area  */}

        <Separator />

        <Socials />
        <Footer />

        {/* End Elements Area  */}
      </main>
    </>
  );
}

export default Loading;
