import logo from "./logo.svg";
import React, { createContext, useEffect, useState, useMemo } from "react";
import "./App.css";
// Import Css Here
import "./assets/scss/style.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./pages/HeaderTopBar";
import SEO from "./pages/SEO";
import HeaderOne from "./pages/HeaderOne";
import Separator from "./pages/Separator";
import SectionTitle from "./pages/SectionTitle";
import SlpitOne from "./pages/SlpitOne";
import SlipFour from "./pages/SlipFour";
import Wrestlers from "./pages/Wrestlers";
import Utility from "./pages/Utility";
import RoadMap2 from "./pages/RoadMap2";
import Team2 from "./pages/Team2";
import CalltoActionFive from "./pages/CalltoActionFive";
import Socials from "./pages/Socials";
import Home from "./Home";
import Holders from "./pages/Holders";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import { setVisible } from "@solana/wallet-adapter-react-ui";
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import Axios from "axios";
import Verification from "./pages/Verification";
import Loading from "./pages/Loading";
import AdminPanel from "./AdminPanel";
import Bookings from "./pages/Bookings";
import Timers from "./pages/Timers";
//import FightClubMain from "./pages/IMPORTANTFIGHTCLUBMAIN";
import FightClubMain from "./pages/FightClubMain";
import BookBillboard from "./pages/BookBillboard";
import QrCodes from "./pages/QrCodes";
import FightClubUnderConstruction from "./pages/FIghtClubUnderConstruction";
import Slots from "./pages/Slots";
import WhiteList from "./pages/WhiteList";
import WhiteListAdmin from "./pages/WhiteListAdmin";
import QrCodeAdmin from "./pages/QrCodeAdmin";
import FightClubAdmin from "./pages/FightClubAdmin";
import FullAdminPanel from "./FullAdminPanel";

require("@solana/wallet-adapter-react-ui/styles.css");

export const Wallet = createContext();
export const Verified = createContext();
export const Generated = createContext();
export const Mini = createContext();
export const Url = createContext();
export const NftList = createContext();
export const UrlCodes = createContext();
export const UrlCheckCodes = createContext();
export const HoldersCheck = createContext();
export const Admins = createContext();
export const TimeSelect = createContext();
export const DateEvent = createContext();
export const TimeEvent = createContext();
export const DateForB = createContext();
function App() {
  const [verified, setVerified] = useState(false);
  const [wallet, setWallet] = useState("");

  const [check2, setCheck2] = useState([]);

  const [mini, setMini] = useState([]);
  const [urlCodes, setUrlCodes] = useState([]);
  const [url, setUrl] = useState("https://verifier.brunowalter.repl.co");
  const [nftList, setNftList] = useState([]);
  const [holdersCheck, setHoldersCheck] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [timeSelect, setTimeSelect] = useState(false);
  const [dateEvent, setDateEvent] = useState("");
  const [timeEvent, setTimeEvent] = useState("");
  const [dateForB, setDateForB] = useState(new Date());
  console.log = () => {};
  useEffect(() => {
    const a = async () =>
      await Axios.get(url + "/api/get").then((response) => {
        setUrlCodes(response.data.map((a) => a));
        console.log("GOT THE GET DATAAAAAAAA");
      });
    a();
  }, []);

  const solNetwork = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork]);
  // initialise all the wallets you want to use
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ solNetwork }),
      new TorusWalletAdapter(),

      new SolletExtensionWalletAdapter(),
      new SolletWalletAdapter(),
    ],
    [solNetwork]
  );
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <WalletModalProvider>
          <TimeSelect.Provider value={[timeSelect, setTimeSelect]}>
            <Url.Provider value={[url, setUrl]}>
              <Wallet.Provider value={[wallet, setWallet]}>
                <Verified.Provider value={[verified, setVerified]}>
                  <Mini.Provider value={[mini, setMini]}>
                    <NftList.Provider value={[nftList, setNftList]}>
                      <UrlCodes.Provider value={[urlCodes, setUrlCodes]}>
                        <UrlCheckCodes.Provider value={[urlCodes, setUrlCodes]}>
                          <HoldersCheck.Provider
                            value={[holdersCheck, setHoldersCheck]}
                          >
                            <Admins.Provider value={[admins, setAdmins]}>
                              <DateEvent.Provider
                                value={[dateEvent, setDateEvent]}
                              >
                                <TimeEvent.Provider
                                  value={[timeEvent, setTimeEvent]}
                                >
                                  <DateForB.Provider
                                    value={[dateForB, setDateForB]}
                                  >
                                    <Router>
                                      <HeaderOne
                                        btnStyle="btn-small round btn-icon"
                                        HeaderSTyle="header-not-transparent"
                                      />
                                      <Switch>
                                        {urlCodes.map((a) => (
                                          <Route
                                            path={
                                              "/" +
                                              a.code +
                                              "4194730" +
                                              a.mint_address
                                            }
                                          >
                                            <Verification
                                              code={a.code}
                                              address={a.name}
                                              mint_address={a.mint_address}
                                            />
                                          </Route>
                                        ))}
                                        <Route
                                          path="/"
                                          exact
                                          component={Home}
                                        />
                                        <Route
                                          path="/holders"
                                          exact
                                          component={Holders}
                                        />
                                        <Route
                                          path="/admin"
                                          exact
                                          component={FullAdminPanel}
                                        />
                                        <Route
                                          path="/fightclub"
                                          exact
                                          component={FightClubMain}
                                        />
                                        <Route
                                          path="/bookbillboard"
                                          exact
                                          component={BookBillboard}
                                        />
                                        <Route
                                          path="/holderqr"
                                          exact
                                          component={QrCodes}
                                        />

                                        <Route
                                          path="/countdown"
                                          exact
                                          component={Timers}
                                        />
                                        <Route
                                          path="/adminbookings"
                                          exact
                                          component={AdminPanel}
                                        />
                                        <Route
                                          path="/underconstruction"
                                          exact
                                          component={FightClubUnderConstruction}
                                        />
                                        <Route
                                          path="/bookings"
                                          exact
                                          component={Bookings}
                                        />
                                        <Route
                                          path="/whitelist"
                                          exact
                                          component={WhiteList}
                                        />
                                        <Route
                                          path="/whitelistadmin"
                                          exact
                                          component={WhiteListAdmin}
                                        />
                                        <Route
                                          path="/qrcodeadmin"
                                          exact
                                          component={QrCodeAdmin}
                                        />
                                        <Route
                                          path="/slots"
                                          exact
                                          component={Slots}
                                        />

                                        <Route path="*">
                                          <Loading />
                                        </Route>
                                      </Switch>
                                    </Router>
                                  </DateForB.Provider>
                                </TimeEvent.Provider>
                              </DateEvent.Provider>
                            </Admins.Provider>
                          </HoldersCheck.Provider>
                        </UrlCheckCodes.Provider>
                      </UrlCodes.Provider>
                    </NftList.Provider>
                  </Mini.Provider>
                </Verified.Provider>
              </Wallet.Provider>
            </Url.Provider>
          </TimeSelect.Provider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
