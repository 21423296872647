// Import Css Here

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./HeaderTopBar";
import SEO from "./SEO";
import HeaderOne from "./HeaderOne";
import Separator from "./Separator";
import SectionTitle from "./SectionTitle";
import SlpitOne from "./SlpitOne";
import SlipFour from "./SlipFour";
import Wrestlers from "./Wrestlers";
import Utility from "./Utility";
import RoadMap2 from "./RoadMap2";
import Team2 from "./Team2";
import CalltoActionFive from "./CalltoActionFive";
import Socials from "./Socials";
import CalltoActionTwo from "./CalltoActionTwo";
import Counter from "./Counter";
import Utility2 from "./Utility2";
import { BsDiscord } from "react-icons/bs";
import Footer from "./Footer";
import { useContext, useEffect, useState } from "react";
import {
  DateEvent,
  DateForB,
  HoldersCheck,
  NftList,
  TimeEvent,
  TimeSelect,
  Url,
  Verified,
  Wallet,
} from "../App";
import Axios from "axios";
import QRCode from "qrcode.react";
import { scopePollingDetectionStrategy } from "@solana/wallet-adapter-base";
import { Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Button from "react-bootstrap/Button";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import Stack from "@mui/material/Stack";
import Modal from "react-bootstrap/Modal";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DatePicker, Space } from "antd";
import Calendar from "react-calendar";
import Time from "./Time.js";
function QrCodes() {
  const [verified, setVerified] = useContext(Verified);
  const [code, setCode] = useState("");
  const [url, setUrl] = useContext(Url);
  const [wallet, setWallet] = useContext(Wallet);
  const [status, setStatus] = useState();
  const [user, setUser] = useState([]);
  const [mintAddress, setMintAddress] = useState("");
  const [nftList, setNftList] = useContext(NftList);
  const [loading, setLoading] = useState(true);
  const [holdersCheck, setHoldersCheck] = useContext(HoldersCheck);
  const [startDate, setStartDate] = useState(new Date());
  const [arrDates, setArrDates] = useState([
    new Date(2022, 11, 4, 9, 0),
    new Date(2022, 11, 4, 11, 0),
    new Date(2022, 11, 4, 13, 0),
    new Date(2022, 11, 4, 15, 0),
    new Date(2022, 11, 4, 17, 0),
    new Date(2022, 11, 4, 19, 0),
    new Date(2022, 11, 4, 21, 0),
    new Date(2022, 11, 4, 23, 0),
  ]);
  const [email, setEmail] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [nftForB, setNftForB] = useState("");
  const [dateValue, setDateValue] = useState();
  const [emailCheck, setEmailCheck] = useState(false);
  const [imageCheck, setImageCheck] = useState(false);
  const [nftCheck, setNftCheck] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [emailBookings, setEmailBookings] = useState([]);
  const [imageUrlBookings, setImageUrlBookings] = useState([]);
  const [nftBookings, setNftBookings] = useState([]);
  const [message, setMessage] = useState([]);
  const [show, setShow] = useState(false);
  const [checkConnection, setCheckConnection] = useState([]);
  const [dateBookings, setDateBookings] = useState([]);
  const [dateCheck, setDateCheck] = useState(false);
  const [value, setValue] = useState();
  const [userBookings, setUserBookings] = useState([]);
  const [dateForB, setDateForB] = useContext(DateForB);
  const [showTime, setShowTime] = useState(false);
  const [timeSelect, setTimeSelect] = useContext(TimeSelect);
  const [timeEvent, setTimeEvent] = useContext(TimeEvent);
  const [dateEvent, setDateEvent] = useContext(DateEvent);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //time selected
  useEffect(() => {
    if (timeSelect == true) {
      setStartDate(dateEvent + " " + timeEvent);
      console.log("time selected");
      console.log("this is time selected" + timeEvent);
      console.log("this is the date Selected" + dateEvent);
    }
  }, [timeSelect]);

  useEffect(() => {
    setTimeSelect(false);
  }, []);

  useEffect(() => {
    console.log("this is the new start date");
    console.log(startDate);
  }, [startDate]);

  //dayjs
  const onChange = (value, dateString) => {
    setStartDate(dateString);
    console.log("This is the date string " + dateString);
  };

  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    var d = new Date(2023, 5, 15);
    var day = dayjs(d);
    return current && current > day.endOf("day");
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(0, 6),
    disabledMinutes: () => range(1, 60),
    disabledSeconds: () => [55, 56],
  });

  const getVerification = async () => {
    await Axios.post(url + "/api/getverification", {
      name: wallet,
    }).then((response) => {
      if (response.data.length > 0) {
        setCode(response.data[0].code);
        setStatus(response.data[0].verified);
        setUser(response.data);

        setLoading(false);
      } else {
        console.log("there is no code");
      }
    });
  };

  const addBillboard = async () => {
    await Axios.post(url + "/api/addbillboard", {
      email: email,
      imageUrl: imageUrl,
      dateTime: startDate,
      nft: nftForB,
      wallet: wallet,
      date: dateEvent,
      time: timeEvent,
    }).then((response) => {
      console.log(response);
      console.log("this is the response of add billboard");
    });
  };
  //get all nfts
  /*useEffect(() => {
      console.log(user);
    }, [user]);*/
  useEffect(() => {
    const interval = setInterval(() => {
      getVerification();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (emailCheck == true) {
      setTimeout(() => {
        setEmailCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [emailCheck]);
  useEffect(() => {
    if (imageCheck == true) {
      setTimeout(() => {
        setImageCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [imageCheck]);

  useEffect(() => {
    if (dateCheck == true) {
      setTimeout(() => {
        setDateCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [dateCheck]);

  useEffect(() => {
    const interval = setInterval(() => {
      const a = async () =>
        await Axios.get(url + "/api/getbookings")
          .then((response, err) => {
            if (response.data.length > 0) {
              setEmailBookings(response.data?.map((a) => a.email));
              setImageUrlBookings(response.data?.map((a) => a.imageUrl));
              setNftBookings(response.data?.map((a) => a.nft));
              setDateBookings(response.data?.map((a) => a.dateTime));
              setCheckConnection([]);
            }
          })
          .catch((error) => {
            setCheckConnection([{ display: "none" }]);
          });
      a();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  //email email apis
  const welcomeEmail = (a) => {
    Axios.post(url + "/api/welcomeemail", {
      email: email,
      imageUrl: imageUrl,
      dateTime: startDate,
      nft: nftForB,
    }).then((response) => {
      if (response.data.message) {
        return console.log(response.data.message);
      } else {
        console.log("mesage sent");
      }
    });
  };

  const welcomeOwner = (a) => {
    Axios.post(url + "/api/welcomeowner", {
      email: email,
      imageUrl: imageUrl,
      dateTime: startDate,
      nft: nftForB,
    }).then((response) => {
      if (response.data.message) {
        return console.log(response.data.message);
      } else {
        console.log("mesage sent");
      }
    });
  };

  const [dateNow, setDateNow] = useState(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );
  const [dateLaunch, setDateLaunch] = useState(
    new Date("2022-12-14T23:00:00Z").toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    })
  );

  //end email apis

  if (verified != true) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  } else if (
    dateNow < dateLaunch &&
    wallet != "3d51hP7BpuH9a99f8QKe1L4vaiPxVqsL2LpW5ydhZsTS" &&
    wallet != "Cfzee1Y8k1oPEC798ubw49CBPU14Ajz56qGtJWZWm39m" &&
    wallet != "DVjkFpgNGGrQRGkDQsp6aU3MdsRHx5Czvo1BdmYTRibB"
  ) {
    return (
      <Redirect
        to={{
          pathname: "/countdown",
        }}
      />
    );
  }
  return (
    <>
      <SEO title="Fight Capital NFT" />
      <main className="page-wrapper">
        <div
          className="slider-area slider-style-1 bg-transparent variation-2 height-750"
          style={checkConnection[0]}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h1 className="display-two">
                    Use Code for All Fight Capital Events <br />
                    <Form.Control
                      onChange={({ target }) => {
                        console.log(target.value);
                        setMintAddress(target.value);
                      }}
                      as="select"
                      style={{ backgroundColor: "black" }}
                      custom
                    >
                      {loading == false ? (
                        <>
                          <option>Please Select an NFT</option>
                          {user.map(
                            (a, index) =>
                              a.mint_address != null &&
                              holdersCheck
                                ?.flat(1)
                                .includes(a.mint_address) && (
                                <option value={a.mint_address}>
                                  {a.mint_name}
                                </option>
                              )
                          )}
                        </>
                      ) : (
                        loading == true && <option>Loading...</option>
                      )}
                    </Form.Control>
                    {/*For{"  "}
                          <Typed
                            strings={["Freelancer.", "Developer.", "Startup."]}
                            typeSpeed={80}
                            backSpeed={5}
                            backDelay={1000}
                            loop
        />*/}
                  </h1>
                  <h3>Status</h3>
                  {user.map((a) =>
                    a.mint_address == mintAddress && a.verified == "false" ? (
                      <>
                        <h4 style={{ color: "green" }}>Unredeemed</h4>

                        {/*    <a
                            href={
                              "http://localhost:3005/" + a.code + a.mint_address
                            }
                          >
                            http://localhost:3005/{a.code}
                            {a.mint_address}
                          </a>*/}
                      </>
                    ) : (
                      a.mint_address == mintAddress &&
                      a.verified == "true" && (
                        <h4 style={{ color: "red" }}>
                          Your Ticket Has Been Redeemed For This Event.
                        </h4>
                      )
                    )
                  )}
                </div>
              </div>
              {user.map(
                (a) =>
                  a.mint_address == mintAddress && (
                    <div className="col-lg-4 order-1 order-lg-2">
                      {a.verified == "true" ? (
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            <h4
                              style={{
                                color: "red",
                                fontSize: "36px",
                                opacity: "1",
                              }}
                            >
                              Redeemed.
                            </h4>
                          </div>
                          <div className="thumbnail" style={{ opacity: "0.5" }}>
                            <QRCode
                              id="qr-gen"
                              level={"H"}
                              includeMargin={true}
                              size={256}
                              style={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "100%",
                                padding: "1rem",
                              }}
                              value={"https://fightcapital.io/"}
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </div>
                      ) : (
                        a.verified == "false" && (
                          <div className="thumbnail">
                            <QRCode
                              id="qr-gen"
                              level={"H"}
                              includeMargin={true}
                              size={256}
                              style={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "100%",
                                padding: "1rem",
                              }}
                              value={
                                "https://fightcapital.io/" +
                                a.code +
                                "4194730" +
                                a.mint_address
                              }
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />

        <Separator />

        <Socials />
        <Footer />

        {/* End Elements Area  */}
      </main>
    </>
  );
}

export default QrCodes;
