import PropTypes from "prop-types";
import React from "react";

const SEO = ({ title }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title} || Wrestling NFTs</title>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="Fight Capital is the exclusive NFT purveyor of top tier talent in the real world of professional wrestling. Fight Capital NFT has acquired both the NFT, and video game rights to these legendary fighters. These NFTs benefit these wrestlers, or their families directly. "
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
