import logo from "./logo.svg";
import "./App.css";
// Import Css Here
import "./assets/scss/style.scss";
import "./assets/css/centermobile.css";
import Modal from "react-bootstrap/Modal";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCodesandbox } from "react-icons/fi";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./pages/HeaderTopBar";
import SEO from "./pages/SEO";
import HeaderOne from "./pages/HeaderOne";
import Separator from "./pages/Separator";
import SectionTitle from "./pages/SectionTitle";
import SlpitOne from "./pages/SlpitOne";
import SlipFour from "./pages/SlipFour";
import Wrestlers from "./pages/Wrestlers";
import Utility from "./pages/Utility";
import RoadMap2 from "./pages/RoadMap2";
import Team2 from "./pages/Team2";
import CalltoActionFive from "./pages/CalltoActionFive";
import Socials from "./pages/Socials";
import CalltoActionTwo from "./pages/CalltoActionTwo";
import Counter from "./pages/Counter";
import Utility2 from "./pages/Utility2";
import { BsDiscord } from "react-icons/bs";
import Footer from "./pages/Footer";
import { useContext, useEffect } from "react";
import { Admins, Verified, Wallet } from "./App";
import Axios from "axios";
import { useState } from "react";
import CalltoActionOne from "./pages/CalltoActionOne";
import { Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {
  Table as T,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import ServiceFour from "./pages/ServiceFour";
import ServiceThree from "./pages/ServiceThree";
import ServiceOne from "./pages/ServiceOne";
import Calendar from "react-calendar";
import Time from "./pages/Time.js";
function FullAdminPanel() {
  const [wallet, setWallet] = useContext(Wallet);
  const [verified, setVerified] = useContext(Verified);
  const [url, setUrl] = useState("https://verifier.brunowalter.repl.co");
  const [codes, setCodes] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [checkConnection, setCheckConnection] = useState([]);
  const [admins, setAdmins] = useContext(Admins);
  const [adminAdd, setAdminAdd] = useState("");
  const [adminAddCheck, setAdminAddCheck] = useState(false);
  const [adminDelete, setAdminDelete] = useState("");
  const [loading, setLoading] = useState(true);
  const [modifyDate, setModifyDate] = useState("");
  const [modifyImg, setModifyImg] = useState("");
  const [modifyNft, setModifyNft] = useState("");
  const [modEmail, setModEmail] = useState("");

  const [show, setShow] = useState(false);
  const [modifyChange, setModifyChange] = useState("");
  const [modifyText, setModifyText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [dateForB, setDateForB] = useState(new Date());
  const [showTime, setShowTime] = useState(false);

  useEffect(() => {
    if (adminAddCheck == true) {
      setTimeout(() => setAdminAddCheck(false), 3000);
    }
  }, [adminAddCheck]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  //adding new wallet to db
  const add = (a, b) => {
    Axios.post(url + "/api/add", {
      name: a,
      code: "test",
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Added";
      }
    });
  };

  const approve = (a) => {
    Axios.post(url + "/api/approve", {
      mint_address: a,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Added";
      }
    });
  };

  const deleteBooking = (a) => {
    Axios.post(url + "/api/deletebooking", {
      mint_address: a,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Added";
      }
    });
  };

  const addAdmin = (a) => {
    Axios.post(url + "/api/addadmin", {
      wallet: adminAdd,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Added";
      }
    });
  };

  const deleteAdmin = (a) => {
    Axios.post(url + "/api/deleteadmin", {
      wallet: adminDelete,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Deleted";
      }
    });
  };

  const changeStatus = (a, b) => {
    Axios.post(url + "/api/changestatus", {
      status: a,
      nft: b,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        setModifyNft("");
        setModifyText("");
        return "Deleted";
      }
    });
  };

  const addinfo = (a) => {
    Axios.post(url + "/api/addinfo", {
      addinfo: modifyText != "" ? modifyText : "N/A",
      nft: modifyNft,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        setModifyNft("");
        setModifyText("");
        return "Deleted";
      }
    });
  };

  const modifyEmail = () => {
    Axios.post(url + "/api/modify", {
      modifyChange: modifyChange,
      modifyNft: modifyNft,
      modifyDateTime: modifyDate,
      modifyImg: modifyImg,
      modEmail: modEmail,
    }).then((response) => {
      if (response.data.message) {
        return console.log(response.data.message);
      } else {
        setModifyChange("");
        setModifyNft("");
        setModifyDate("");
        setModifyImg("");
        setModEmail("");
        console.log("mesage sent");
      }
    });
  };

  const approveEmail = (nft, date, image, email) => {
    console.log("this is the approval stuff");
    console.log(nft, date, image, email);
    Axios.post(url + "/api/approvals", {
      modifyNft: nft,
      modifyDateTime: date,
      modifyImg: image,
      modEmail: email,
    }).then((response) => {
      if (response.data.message) {
        return console.log(response.data.message);
      }
    });
  };

  //get all wallets
  useEffect(() => {
    const a = async () =>
      await Axios.get(url + "/api/get").then((response) => {
        setCodes(response.data.map((a) => a.name));
      });
    a();
  }, []);

  /*useEffect(() => {
    if (
      wallet != null &&
      wallet != "" &&
      wallet != undefined &&
      verified == true &&
      codes.length > 0
    ) {
      if (codes.includes(wallet) == false) {
        console.log("wallet " + wallet);
        console.log("verified: " + verified);
        console.log(codes);
        add(wallet);
      } else {
        console.log("did not add wallet" + wallet + verified);
      }
    }
  }, [codes, wallet, verified]);*/

  useEffect(() => {
    if (wallet == "" || wallet == null || wallet == undefined) {
      setVerified(false);
    }
  }, [wallet]);

  const changeLiveStatus = (a) => {
    Axios.post(url + "/api/changelivestatus", {
      site: "fightclub",
      status: a,
    }).then((response) => {
      alert("Changed Status of Site to: " + a);
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const a = async () =>
        await Axios.get(url + "/api/getbookings")
          .then((response, err) => {
            setLoading(false);
            if (response.data.length > 0) {
              setBookings(response.data);
            } else {
              setBookings([]);
            }
          })
          .catch((error) => {
            setCheckConnection([{ display: "none" }]);
          });
      a();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (admins.includes(wallet) == false) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  return (
    <>
      <SEO title="Fight Capital Admin Panel" />
      <Modal show={show} onHide={handleClose} style={{ color: "black" }}>
        <Modal.Header>
          <Modal.Title style={{ color: "black" }}>
            Please Specify What You would like modified
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Please Specify Modification</Form.Label>

              <Form.Control
                onChange={({ target }) => {
                  console.log(target.value);
                  setModifyChange(target.value);
                }}
                as="select"
                custom
              >
                <>
                  <option>Please Select Modification</option>
                  <option value="Date">Change Date</option>
                  <option value="Image">Change Image</option>
                  <option value="Date/Image">Change Date/Image</option>
                </>
              </Form.Control>
              <Form.Label htmlFor="inputPassword5">
                Additonal Information
              </Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                onChange={({ target }) => setModifyText(target.value)}
              />
              <Form.Text id="passwordHelpBlock" muted>
                Please insert any additional information required
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              changeStatus(modifyChange, modifyNft);
              modifyEmail();
              addinfo();
              handleClose();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <main className="page-wrapper" style={checkConnection}>
        {/* Start Slider Area  */}

        {/* End Slider Area  */}
        {loading == true ? (
          <div className="container">
            <div className="rwt-advance-tab-area rn-section-gap">
              <div className="container">
                <div className="row mb--40"></div>
                <img
                  src="https://i.ibb.co/0XCR1nc/955.png"
                  style={{
                    height: "400px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                />
                <div style={{ width: "80%", padding: "auto", margin: "auto" }}>
                  <h1 style={{ textAlign: "center" }}>Loading</h1>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="rwt-advance-tab-area rn-section-gap">
              <div className="container">
                <div className="row mb--40"></div>
                <img
                  src="https://i.ibb.co/0XCR1nc/955.png"
                  style={{
                    height: "400px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                />
                <div style={{ width: "80%", padding: "auto", margin: "auto" }}>
                  <h1 style={{ textAlign: "center" }}>Booking Stats</h1>
                  <ServiceOne
                    textAlign="text-center"
                    serviceStyle="service__style--1 bg-color-blackest radius mt--25"
                    book={bookings}
                  />
                </div>
                <Form style={{ marginTop: "30px" }}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h3 style={{ textAlign: "center" }}>Site Status</h3>
                    <div className="button-group" style={{ display: "flex" }}>
                      <Link
                        className="btn-default btn-medium btn-border"
                        type="submit"
                        onClick={() => {
                          changeLiveStatus("true");
                        }}
                        style={{
                          display: "block",
                          width: "49%",

                          marginBottom: "5px",
                        }}
                      >
                        Live
                      </Link>{" "}
                      {"  "}
                      <Link
                        className="btn-default btn-medium btn-border"
                        type="submit"
                        onClick={() => {
                          changeLiveStatus("false");
                        }}
                        style={{
                          display: "block",
                          width: "49%",

                          marginBottom: "5px",
                        }}
                      >
                        Offline
                      </Link>
                    </div>
                  </Form.Group>
                  <div className="button-group"></div>
                </Form>

                <Link
                  className="btn-default btn-medium btn-border"
                  type="submit"
                  to="/whitelistadmin"
                  style={{
                    display: "block",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "5px",
                  }}
                >
                  Manage WhiteList
                </Link>
                <Link
                  className="btn-default btn-medium btn-border"
                  type="submit"
                  style={{
                    display: "block",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "5px",
                  }}
                  to="/qrcodeadmin"
                >
                  QR Code Admissions
                </Link>
                <Link
                  className="btn-default btn-medium btn-border"
                  type="submit"
                  style={{
                    display: "block",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "5px",
                  }}
                  to="/adminbookings"
                >
                  Bookings Manager
                </Link>
                <Form style={{ marginTop: "40px", marginBottom: "40px" }}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      Enter Admin Wallet Address
                    </Form.Label>

                    {adminAddCheck == false ? (
                      <Form.Control
                        type="text"
                        placeholder="Enter New Admin Wallet"
                        onChange={({ target }) => setAdminAdd(target.value)}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        placeholder="Enter New Admin Wallet"
                        onChange={({ target }) => setAdminAdd(target.value)}
                        style={{ border: "3px solid red" }}
                      />
                    )}
                  </Form.Group>
                  <div className="button-group">
                    <Link
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                      className="btn-default btn-medium btn-border"
                      type="submit"
                      onClick={() => {
                        adminAdd != "" && admins.includes(adminAdd) == false
                          ? addAdmin()
                          : setAdminAddCheck(true);
                      }}
                    >
                      Add Admin
                    </Link>
                  </div>
                </Form>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Select an Admin Wallet Address</Form.Label>

                    <Form.Control
                      onChange={({ target }) => {
                        console.log(target.value);
                        setAdminDelete(target.value);
                      }}
                      as="select"
                      style={{ backgroundColor: "black" }}
                      custom
                    >
                      <>
                        <option>Please Select an Admin to Delete</option>
                      </>
                      {admins.map(
                        (a) =>
                          a !=
                            "3d51hP7BpuH9a99f8QKe1L4vaiPxVqsL2LpW5ydhZsTS" && (
                            <option value={a}>{a}</option>
                          )
                      )}
                    </Form.Control>
                  </Form.Group>
                  <div className="button-group">
                    <Link
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                      className="btn-default btn-medium btn-border"
                      type="submit"
                      onClick={() => {
                        adminDelete != "" && deleteAdmin();
                      }}
                    >
                      Delete Admin
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}

        {/* Start Elements Area  */}

        {!loading && <Footer />}

        {/* End Elements Area  */}
      </main>
    </>
  );
}

export default FullAdminPanel;
