import logo from "./logo.svg";
import "./App.css";
// Import Css Here
import "./assets/scss/style.scss";
import "./assets/css/centermobile.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCodesandbox } from "react-icons/fi";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./pages/HeaderTopBar";
import SEO from "./pages/SEO";
import HeaderOne from "./pages/HeaderOne";
import Separator from "./pages/Separator";
import SectionTitle from "./pages/SectionTitle";
import SlpitOne from "./pages/SlpitOne";
import SlipFour from "./pages/SlipFour";
import Wrestlers from "./pages/Wrestlers";
import Utility from "./pages/Utility";
import RoadMap2 from "./pages/RoadMap2";
import Team2 from "./pages/Team2";
import CalltoActionFive from "./pages/CalltoActionFive";
import Socials from "./pages/Socials";
import CalltoActionTwo from "./pages/CalltoActionTwo";
import Counter from "./pages/Counter";
import Utility2 from "./pages/Utility2";
import { BsDiscord } from "react-icons/bs";
import Footer from "./pages/Footer";
import { useContext, useEffect } from "react";
import { Verified, Wallet } from "./App";
import Axios from "axios";
import { useState } from "react";
function Home() {
  const [wallet, setWallet] = useContext(Wallet);
  const [verified, setVerified] = useContext(Verified);
  const [url, setUrl] = useState("https://verifier.brunowalter.repl.co");
  const [codes, setCodes] = useState([]);

  //adding new wallet to db
  const add = (a, b) => {
    Axios.post(url + "/api/add", {
      name: a,
      code: "test",
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Added";
      }
    });
  };

  //get all wallets
  useEffect(() => {
    const a = async () =>
      await Axios.get(url + "/api/get").then((response) => {
        setCodes(response.data.map((a) => a.name));
      });
    a();
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  /*useEffect(() => {
    if (
      wallet != null &&
      wallet != "" &&
      wallet != undefined &&
      verified == true &&
      codes.length > 0
    ) {
      if (codes.includes(wallet) == false) {
        console.log("wallet " + wallet);
        console.log("verified: " + verified);
        console.log(codes);
        add(wallet);
      } else {
        console.log("did not add wallet" + wallet + verified);
      }
    }
  }, [codes, wallet, verified]);*/

  useEffect(() => {
    if (wallet == "" || wallet == null || wallet == undefined) {
      setVerified(false);
    }
  }, [wallet]);

  useEffect(() => {
    console.log(wallet);
    console.log("this is the wallet");
  }, []);
  return (
    <>
      <SEO title="Fight Capital NFT" />
      <main className="page-wrapper">
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h1 className="display-two">
                    Fight Capital <br /> NFT Collection <br />
                    {/*For{"  "}
                        <Typed
                          strings={["Freelancer.", "Developer.", "Startup."]}
                          typeSpeed={80}
                          backSpeed={5}
                          backDelay={1000}
                          loop
      />*/}
                  </h1>
                  <h3>EXCLUSIVE FULLY LICENSED PROS</h3>
                  <h4 style={{ color: "orange" }}>
                    THE BIGGEST WRESTLING PERSONALITIES
                  </h4>

                  <div className="button-group">
                    {verified &&
                    wallet != undefined &&
                    wallet != "" &&
                    wallet != null ? (
                      <>
                        <Link
                          className="btn-default btn-medium btn-border round btn-icon"
                          to="/fightclub"
                          style={{ width: "65%" }}
                        >
                          Fight Club
                          <i className="icon">
                            <FiCodesandbox />
                          </i>
                        </Link>
                        <br />
                      </>
                    ) : (
                      <>
                        <Link
                          className="btn-default btn-medium btn-border round btn-icon"
                          to="/"
                          style={{ width: "65%", backgroundColor: "red" }}
                          onClick={() =>
                            alert("Must be a Fight Capital Verified Holder.")
                          }
                        >
                          Fight Club
                          <i className="icon">
                            <FiCodesandbox />
                          </i>
                        </Link>
                        <br />
                      </>
                    )}
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to={{ pathname: "https://fightcapital.io/mint" }}
                      target="_blank"
                    >
                      Mint{" "}
                      <i className="icon">
                        <FiCodepen />
                      </i>
                    </Link>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to={{ pathname: "https://store.fightcapital.io" }}
                      target="_blank"
                    >
                      Mint CC/Debit
                      <i className="icon">
                        <FiCodepen />
                      </i>
                    </Link>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to={{ pathname: "https://discord.gg/fightcapital" }}
                      target="_blank"
                    >
                      Discord{" "}
                      <i className="icon">
                        <BsDiscord />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-1 order-lg-2">
                <div className="thumbnail">
                  <img src="./images/FC/955.png" alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-advance-tab-area">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle />
              </div>
            </div>
            <Carousel
              cols={4}
              rows={1}
              gap={10}
              loop={true}
              autoplay={5000}
              hideArrow={true}
            >
              <Carousel.Item>
                <img width="100%" src="./images/FC/11.png" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/10.png" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/15.png" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/7.png" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/5.png" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/6.png" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/1.jpeg" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/3.png" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/4.jpeg" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/9.png" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/12.png" />
              </Carousel.Item>
              <Carousel.Item>
                <img width="100%" src="./images/FC/13.png" />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />

        {/* Start Service Area  */}

        <div className="rwt-advance-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Project Details"
                  description=""
                />
              </div>
            </div>
            <SlpitOne
              title="The Alpha Mint Contains:"
              imgs="/images/FC/2475.jpeg"
              description={
                <ul>
                  <li>
                    625 total NFTs per character are created, while only 500
                    NFTs per character are available to via the public mint.
                  </li>
                  <li>
                    In every instance the talent, or their estate, is either
                    issued 10% of their total NFT’s base character to hold or
                    sell, or receive a percentage of their initial mint sales.
                  </li>
                  <li>
                    Fight Capital retains the remaining NFTs of the base
                    character release for air drops, giveaways, and game
                    rewards. (100% of all chase rarity tiers will be issued in
                    the public blind box sale). The exact character retention
                    numbers will be released prior to the mint being made
                    available for sale.
                  </li>
                </ul>
              }
            />
          </div>
        </div>

        <Separator />
        {/* Start Service Area  */}

        <div className="rwt-advance-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Our Mission"
                  description=""
                />
              </div>
            </div>
            <SlipFour
              title="Mission Statement"
              description="We at the Fight Capital NFT Collective are passionate about Web3 technology that NFTs provide, 
                and their potential to create new layers to the universe of professional wrestling. Porting these 
                legends, our heroes, into a digital form on the blockchain, allows us the opportunity to 
                commemorate, and honor often under appreciated, often under compensated, talent well beyond 
                their physical years in the ring. Our mission is to create a platform where these fighters can 
                contribute and participate in this expanding universe, forever. Each NFT is officially licensed, and 
                personally benefits the talent, or their estate directly. We are rebels, trend setters, trail blazers, 
                leading a path to a universe that will allow professional wrestling to grow into truly uncharted 
                territories.
    "
              img="/images/FC/3487.jpeg"
            />
          </div>
        </div>
        <Separator />
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            {/*<div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Signed Talent"
                  description=""
                />
                <Wrestlers />
              </div>
            </div>*/}
            <Separator />
            {/* Start Elements Area--UTILITY SECTION  */}
            <div className="rwt-advance-tab-area rn-section-gap">
              <div className="container">
                <div className="row mb--40">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      title="Project Utilities"
                      description="Here are several Utility Fight Capital Offers"
                    />
                  </div>
                </div>

                {/*UTILITY STUFF */}
                <Carousel
                  cols={1}
                  rows={1}
                  gap={10}
                  loop={true}
                  autoplay={5000}
                  hideArrow={false}
                  fade={true}
                >
                  <Carousel.Item>
                    <CalltoActionFive
                      tit="BILLBOARD SPACE"
                      subtit={
                        <div>
                          •1 HOUR IN ROTATION ON FREMONT STREET LAS VEGAS TO
                          BUILD BRAND AWARENESS AND GET MORE EYES ON YOUR
                          PROJECT TOOLS OR NFT
                        </div>
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <CalltoActionFive
                      tit="UNPRECIDENTED UTILITY"
                      subtit={
                        <div>
                          •ACCESS TO WRESTLING EVENTS
                          <br />
                          •WATCH PARTIES
                          <br />
                          •MEET & GREETS
                        </div>
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <CalltoActionFive
                      tit="VIP ACCESS"
                      subtit={
                        <div>
                          •VIP ACCESS TO @NERDBARLV
                          <br />
                          •EXCLUSIVE MERCHANDISE
                          <br />
                          •EPIC EVENTS
                        </div>
                      }
                    />
                  </Carousel.Item>
                </Carousel>

                {/* <Utility />*/}
              </div>
            </div>
            <Separator />
            {/* End Elements Area  */}
            {/* Start Elements Area--TimeLINE SECTION  
            <div className="rwt-advance-tab-area rn-section-gap">
              <div className="container">
                <div className="row mb--40">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      title="ROADMAP"
                      description="This is a roadmap"
                    />
                  </div>
                </div>

                <RoadMap2 />
              </div>
            </div>
            */}
            {/* End Elements Area  */}
            <Separator />
            {/* Start Elements Area--TEAM Section */}
            {/*TEAM
            <div className="rwt-advance-tab-area rn-section-gap">
              <div className="container">
                <div className="row mb--40">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      title="Team"
                      description=""
                    />
                  </div>
                </div>


                <Team2
                  column="col-lg-4 col-md-6 col-12 mt--10"
                  teamStyle="team-style-default style-two"
                  id="teamstyles"
            />
              </div>
            </div>*/}
            {/* End Elements Area  */}
            <Separator />

            {/* Start Elements Area  */}
            <div className="rwt-advance-tab-area rn-section-gap">
              <div className="container">
                <div className="row mb--40">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      title="Mint Today"
                      description="Purchase a Fight Capital NFT using Solana or CC/Debit"
                    />
                  </div>
                </div>
                <CalltoActionFive
                  tit="Mint using Solana"
                  subtit={"Please ensure you are using a Solana Wallet Browser"}
                  btntxt="Mint Now"
                  btnhref="https://fightcapital.io/mint"
                />
                <Separator />
                <br />
                <CalltoActionFive
                  tit="Mint with CC/Debit"
                  subtit={"All Credit Cards Accepted"}
                  btntxt="Mint Now"
                  btnhref="http://store.fightcapital.io/"
                />
              </div>
            </div>
            {/* End Elements Area  */}
            <Separator />
            {/* Start Service Area  */}
          </div>
        </div>

        {/* Start Elements Area  */}

        <Socials />
        <Footer />

        {/* End Elements Area  */}
      </main>
    </>
  );
}

export default Home;
