import React, { useContext, useState, useEffect } from "react";
import Countdown from "react-countdown";
import { Redirect } from "react-router-dom";
import { Url, Verified } from "../App";
import Axios from "axios";
export default function Timers() {
  const Completionist = () => <span>You are good to go!</span>;
  var ReactFitText = require("react-fittext");
  const [verified, setVerified] = useContext(Verified);
  const [url, setUrl] = useContext(Url);
  const [checkConnection, setCheckConnection] = useState("");
  const [loading, setLoading] = useState(false);

  const [dateLaunch, setDateLaunch] = useState(
    new Date("2022-12-15T02:00:00Z")
  );
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return verified == true ? (
        <Redirect
          to={{
            pathname: "/holders",
          }}
        />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    } else {
      // Render a countdown
      return (
        <div>
          <img
            src="https://i.ibb.co/0XCR1nc/955.png"
            style={{
              height: "400px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          />
          <h2
            style={{
              textAlign: "center",
            }}
          >
            Countdown to Fight Capital Holders Dashboard
          </h2>
          <ReactFitText compressor={0.7}>
            {loading == true ? (
              <h1
                style={{
                  width: "100vw",

                  fontSize: "300px",
                  textAlign: "center",
                  marginTop: "1vh",
                }}
              >
                Loadingstuffff
              </h1>
            ) : (
              <h1
                style={{
                  width: "100vw",

                  fontSize: "300px",
                  textAlign: "center",
                  marginTop: "1vh",
                }}
              >
                {days < 10 ? "0" + days : days}:
                {hours < 10 ? "0" + hours : hours}:
                {minutes < 10 ? "0" + minutes : minutes}:
                {seconds < 10 ? "0" + seconds : seconds}
              </h1>
            )}
          </ReactFitText>
        </div>
      );
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Countdown
        date={new Date("2022-12-14T23:00:00Z")}
        renderer={renderer}
        style={{ width: "100%" }}
      />
    </div>
  );
}
