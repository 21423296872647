// Import Css Here

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./HeaderTopBar";
import SEO from "./SEO";
import HeaderOne from "./HeaderOne";
import Separator from "./Separator";
import SectionTitle from "./SectionTitle";
import Table from "react-bootstrap/Table";
import SlpitOne from "./SlpitOne";
import SlipFour from "./SlipFour";
import Wrestlers from "./Wrestlers";
import Utility from "./Utility";
import RoadMap2 from "./RoadMap2";
import Team2 from "./Team2";
import CalltoActionFive from "./CalltoActionFive";
import Socials from "./Socials";
import CalltoActionTwo from "./CalltoActionTwo";
import Counter from "./Counter";
import Utility2 from "./Utility2";
import { BsDiscord } from "react-icons/bs";
import Footer from "./Footer";
import { useContext, useEffect, useState } from "react";
import {
  Admins,
  DateEvent,
  DateForB,
  HoldersCheck,
  NftList,
  TimeEvent,
  TimeSelect,
  Url,
  Verified,
  Wallet,
} from "../App";
import Axios from "axios";
import QRCode from "qrcode.react";
import { scopePollingDetectionStrategy } from "@solana/wallet-adapter-base";
import { Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Button from "react-bootstrap/Button";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import Stack from "@mui/material/Stack";
import Modal from "react-bootstrap/Modal";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DatePicker, Space } from "antd";
import Calendar from "react-calendar";
import Time from "./Time.js";
import { QrCode } from "@mui/icons-material";
function QrCodeAdmin() {
  const [verified, setVerified] = useContext(Verified);
  const [code, setCode] = useState("");
  const [url, setUrl] = useContext(Url);
  const [wallet, setWallet] = useContext(Wallet);
  const [status, setStatus] = useState();
  const [user, setUser] = useState([]);
  const [mintAddress, setMintAddress] = useState("");
  const [nftList, setNftList] = useContext(NftList);
  const [loading, setLoading] = useState(true);
  const [holdersCheck, setHoldersCheck] = useContext(HoldersCheck);
  const [startDate, setStartDate] = useState(new Date());
  const [arrDates, setArrDates] = useState([
    new Date(2022, 11, 4, 9, 0),
    new Date(2022, 11, 4, 11, 0),
    new Date(2022, 11, 4, 13, 0),
    new Date(2022, 11, 4, 15, 0),
    new Date(2022, 11, 4, 17, 0),
    new Date(2022, 11, 4, 19, 0),
    new Date(2022, 11, 4, 21, 0),
    new Date(2022, 11, 4, 23, 0),
  ]);
  const [email, setEmail] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [nftForB, setNftForB] = useState("");
  const [dateValue, setDateValue] = useState();
  const [emailCheck, setEmailCheck] = useState(false);
  const [imageCheck, setImageCheck] = useState(false);
  const [nftCheck, setNftCheck] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [emailBookings, setEmailBookings] = useState([]);
  const [imageUrlBookings, setImageUrlBookings] = useState([]);
  const [nftBookings, setNftBookings] = useState([]);
  const [message, setMessage] = useState([]);
  const [show, setShow] = useState(false);
  const [checkConnection, setCheckConnection] = useState([]);
  const [dateBookings, setDateBookings] = useState([]);
  const [dateCheck, setDateCheck] = useState(false);
  const [value, setValue] = useState();
  const [userBookings, setUserBookings] = useState([]);
  const [dateForB, setDateForB] = useContext(DateForB);
  const [showTime, setShowTime] = useState(false);
  const [timeSelect, setTimeSelect] = useContext(TimeSelect);
  const [timeEvent, setTimeEvent] = useContext(TimeEvent);
  const [dateEvent, setDateEvent] = useContext(DateEvent);
  const [name, setName] = useState("");
  const [eth_address, setEth_address] = useState("");
  const [nameCheck, setNameCheck] = useState(false);
  const [ethCheck, setEthCheck] = useState(false);
  const [admins, setAdmins] = useContext(Admins);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fullWhiteList, setFullWhiteList] = useState([]);
  const [whiteListLoad, setWhiteListLoad] = useState(true);
  const [fullQr, setFullQr] = useState([]);
  const [qrCodeLoad, setQrCodeLoad] = useState(true);

  const addWhiteList = async () => {
    await Axios.post(url + "/api/addwhitelist", {
      name: name,
      email: email,
      nft: nftForB,
      eth_address: eth_address,
    }).then((response) => {
      console.log(response);
      console.log("this is the response of add whitelist");
    });
  };
  //time selected
  useEffect(() => {
    if (timeSelect == true) {
      setStartDate(dateEvent + " " + timeEvent);
      console.log("time selected");
      console.log("this is time selected" + timeEvent);
      console.log("this is the date Selected" + dateEvent);
    }
  }, [timeSelect]);

  useEffect(() => {
    setTimeSelect(false);
  }, []);

  useEffect(() => {
    console.log("this is the new start date");
    console.log(startDate);
  }, [startDate]);

  //dayjs
  const onChange = (value, dateString) => {
    setStartDate(dateString);
    console.log("This is the date string " + dateString);
  };

  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    var d = new Date(2023, 5, 15);
    var day = dayjs(d);
    return current && current > day.endOf("day");
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(0, 6),
    disabledMinutes: () => range(1, 60),
    disabledSeconds: () => [55, 56],
  });

  const getVerification = async () => {
    await Axios.post(url + "/api/getverification", {
      name: wallet,
    }).then((response) => {
      if (response.data.length > 0) {
        setCode(response.data[0].code);
        setStatus(response.data[0].verified);
        setUser(response.data);

        setLoading(false);
      } else {
        console.log("there is no code");
      }
    });
  };

  const addBillboard = async () => {
    await Axios.post(url + "/api/addbillboard", {
      email: email,
      imageUrl: imageUrl,
      dateTime: startDate,
      nft: nftForB,
      wallet: wallet,
      date: dateEvent,
      time: timeEvent,
    }).then((response) => {
      console.log(response);
      console.log("this is the response of add billboard");
    });
  };
  //get all nfts
  /*useEffect(() => {
      console.log(user);
    }, [user]);*/
  useEffect(() => {
    const interval = setInterval(() => {
      getVerification();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (emailCheck == true) {
      setTimeout(() => {
        setEmailCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [emailCheck]);
  useEffect(() => {
    if (imageCheck == true) {
      setTimeout(() => {
        setImageCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [imageCheck]);

  useEffect(() => {
    if (dateCheck == true) {
      setTimeout(() => {
        setDateCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [dateCheck]);
  useEffect(() => {
    if (nftCheck == true) {
      setTimeout(() => {
        setNftCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [nftCheck]);
  useEffect(() => {
    if (nameCheck == true) {
      setTimeout(() => {
        setNameCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [nameCheck]);

  useEffect(() => {
    if (ethCheck == true) {
      setTimeout(() => {
        setEthCheck(false);
        setMessage([]);
      }, 3000);
    } else {
      setMessage([]);
    }
  }, [ethCheck]);

  useEffect(() => {
    const interval = setInterval(() => {
      const a = async () =>
        await Axios.get(url + "/api/getverifiedqr")
          .then((response, err) => {
            if (response.data.length > 0) {
              setFullQr(response.data);
              setCheckConnection([]);
              setQrCodeLoad(false);
            } else {
              setWhiteListLoad(false);
              setQrCodeLoad(false);
            }
          })
          .catch((error) => {
            setCheckConnection([{ display: "none" }]);
          });
      a();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  //email email apis
  const welcomeEmail = (a) => {
    Axios.post(url + "/api/welcomeemail", {
      email: email,
      imageUrl: imageUrl,
      dateTime: startDate,
      nft: nftForB,
    }).then((response) => {
      if (response.data.message) {
        return console.log(response.data.message);
      } else {
        console.log("mesage sent");
      }
    });
  };

  const welcomeOwner = (a) => {
    Axios.post(url + "/api/welcomeowner", {
      email: email,
      imageUrl: imageUrl,
      dateTime: startDate,
      nft: nftForB,
    }).then((response) => {
      if (response.data.message) {
        return console.log(response.data.message);
      } else {
        console.log("mesage sent");
      }
    });
  };

  const deleteAdmission = (a) => {
    Axios.post(url + "/api/deleteadmission", {
      nft: a,
    }).then((response) => {
      if (response.data.message) {
        return console.log(response.data.message);
      } else {
        console.log("mesage sent");
        setFullQr(fullQr.filter((b) => b.mint_address != a));
      }
    });
  };

  const [dateNow, setDateNow] = useState(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );
  const [dateLaunch, setDateLaunch] = useState(
    new Date("2022-12-14T23:00:00Z").toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    })
  );

  //end email apis

  if (verified != true) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  } else if (
    dateNow < dateLaunch &&
    wallet != "3d51hP7BpuH9a99f8QKe1L4vaiPxVqsL2LpW5ydhZsTS" &&
    wallet != "Cfzee1Y8k1oPEC798ubw49CBPU14Ajz56qGtJWZWm39m" &&
    wallet != "DVjkFpgNGGrQRGkDQsp6aU3MdsRHx5Czvo1BdmYTRibB"
  ) {
    return (
      <Redirect
        to={{
          pathname: "/countdown",
        }}
      />
    );
  }

  if (admins.includes(wallet) == false) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  return (
    <>
      <SEO title="Fight Capital NFT" />
      <main className="page-wrapper">
        {/* Start Slider Area  */}
        <Modal show={show} onHide={handleClose} style={{ color: "black" }}>
          <Modal.Header>
            <Modal.Title style={{ color: "black" }}>
              Entry Submitted!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your entry has been submitted. You will receive an Email if request
            has been Approved. Please Check the Discord and/or your email for
            more updates.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* End Slider Area  */}

        {/*  <Container>
            <Row>
              <Carousel
                cols={4}
                rows={1}
                gap={10}
                loop={true}
                autoplay={5000}
                hideArrow={false}
              >
                {user.map(
                  (a) =>
                    holdersCheck.includes(a.mint_address) && (
                      <Carousel.Item>
                        <Card
                          style={{
                            backgroundColor: "black",
                            color: "white",
                          }}
                        >
                          <img width="100%" src={a.mint_image} />
                          <Card.Body>
                            <Card.Title>{a.mint_name}</Card.Title>
                          </Card.Body>
                        </Card>
                      </Carousel.Item>
                    )
                )}
              </Carousel>
            </Row>
                        </Container>*/}

        <div className="container">
          <div className="rwt-advance-tab-area rn-section-gap">
            <div className="container">
              <div className="row mb--40">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="Fight Capital Admissions to Event"
                  />
                </div>
              </div>
              {/*       <Link
                className="btn-default btn-medium btn-border round btn-icon"
                to="/bookings"
                style={{
                  display: "block",
                  width: "50%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "5px",
                }}
              >
                View Bookings
              </Link>
              */}
              {qrCodeLoad ? (
                <h2 style={{ textAlign: "center" }}>LOADING...</h2>
              ) : fullQr.length < 1 ? (
                <h2 style={{ textAlign: "center" }}>NO RESULTS FOUND</h2>
              ) : (
                <>
                  <Table striped bordered variant="light" hover responsive>
                    <thead>
                      <tr>
                        <th>Wallet Address</th>
                        <th>Mint Address</th>
                        <th>Admission</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fullQr.map((a) => (
                        <tr>
                          <td>{a.name}</td>
                          <td>{a.mint_address}</td>
                          <td>Admitted</td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => deleteAdmission(a.mint_address)}
                              style={{ width: "100%" }}
                            >
                              X
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </div>
            <h2 style={{ textAlign: "center" }}>
              Number of Admissions:{fullQr.length}
            </h2>
          </div>
        </div>

        {/* End Elements Area  */}

        <Separator />

        <div style={{ position: "absolute", bottom: "10px" }}>
          <Footer />
        </div>

        {/* End Elements Area  */}
      </main>
    </>
  );
}

export default QrCodeAdmin;
