import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Calendar from "react-calendar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { DateEvent, DateForB, TimeEvent, TimeSelect, Url } from "../App";
import Axios from "axios";

const time = [
  "12:00AM",
  "01:00AM",
  "02:00AM",
  "03:00AM",
  "04:00AM",
  "05:00AM",
  "06:00AM",
  "07:00AM",
  "08:00AM",
  "09:00AM",
  "10:00AM",
  "11:00AM",
  "12:00PM",
  "01:00PM",
  "02:00PM",
  "03:00PM",
  "04:00PM",
  "05:00PM",
  "06:00PM",
  "07:00PM",
  "08:00PM",
  "09:00PM",
  "10:00PM",
  "11:00PM",
];

function Times(props) {
  const [timeEvent, setTimeEvent] = useContext(TimeEvent);
  const [info, setInfo] = useState(false);
  const [dateEvent, setDateEvent] = useContext(DateEvent);
  const [loading, setLoading] = useState(true);
  const [timeSelect, setTimeSelect] = useContext(TimeSelect);
  const [dataTime, setDataTime] = useState([]);
  const [url, setUrl] = useContext(Url);
  const [dateForB, setDateForB] = useContext(DateForB);

  useEffect(() => {
    setDateEvent(dateForB.toLocaleDateString());
  }, [dateForB]);

  useEffect(() => {
    setLoading(true);
    selectBookings();
  }, [dateForB]);

  function displayInfo(e) {
    setInfo(true);
    setTimeEvent(e.target.innerText);
    setTimeSelect(true);
    console.log("this is the displayInfo");
    console.log(e.target.innerText, dateEvent);
  }

  //get the times for the date selected and store in variable
  //setloading to false
  //Select all from bookings where date==dat
  //setdataTime(response.data.map(a=>a.time))
  //Loop every 5 seconds.
  /*const dataTime = [
    "12:00AM",
    "05:00PM",
    "11:00PM",
    "01:00AM",
    "01:00AM",
    "02:00AM",
    "03:00AM",
    "05:00AM",
    "06:00AM",
    "07:00AM",
  ];*/

  const selectBookings = async () => {
    console.log("getting selection bookings");
    await Axios.post(url + "/api/selectbookingsdate", {
      date: dateForB.toLocaleDateString(),
    }).then((response) => {
      if (response.data.length > 0) {
        setDataTime(response.data.map((a) => a.time));
        console.log(dateForB.toLocaleDateString());
        setLoading(false);
      } else {
        console.log(dateForB.toLocaleDateString());
        setDataTime([]);
        setLoading(false);
      }
    });
  };

  /* useEffect(() => {
    const interval = setInterval(() => {
      selectBookings();
    }, 1000);
    return () => clearInterval(interval);
  }, []);
*/
  return (
    <Container>
      <Row xs={3} md={4} className="g-4">
        {loading == true ? (
          <img
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src="https://media.tenor.com/On7kvXhzml4AAAAi/loading-gif.gif"
          />
        ) : (
          time.map((times) => {
            return (
              //check if variable does not include times
              dataTime.includes(times) == false && (
                <Col>
                  <Button
                    className="btn-default btn-medium btn-border round btn-icon"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      padding: 0,
                    }}
                    onClick={(e) => displayInfo(e)}
                  >
                    {times}
                  </Button>
                </Col>
              )
            );
          })
        )}
      </Row>
    </Container>
  );
}

export default Times;

//after selecting this. setDateTime(dat+timeEvent) it hides the complete time area and unhides the form.
//users fill form and submit. Upon submission, the dat, and the timeevent get submitted, and datetime gets submitted too.
