import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight, FiCodepen } from "react-icons/fi";
import "../assets/css/calltoaction.css";

const CalltoActionFive = ({
  tit,
  subtit,
  btntxt = "",
  btnhref,
  vidurl = "",
}) => {
  const callToActionData = {
    title: tit,
    subtitle: subtit,
    btnText: btntxt,
  };
  return (
    <div className="rn-callto-action clltoaction-style-default style-5">
      <div className="container">
        <div className="row row--0 align-items-center content-wrapper">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <h2 className="title" id="UtilityTitle">
                  {callToActionData.title}
                </h2>

                <h6 className="subtitle" id="utilityText">
                  {callToActionData.subtitle}
                </h6>

                <br />
                {vidurl != "" ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${vidurl}`,
                    }}
                  />
                ) : (
                  ""
                )}

                {callToActionData.btnText != "" &&
                callToActionData != undefined ? (
                  <div className="call-to-btn text-center">
                    <a className="btn-default btn-icon" href={btnhref}>
                      {callToActionData.btnText}{" "}
                      <i className="icon">
                        <FiCodepen />
                      </i>
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionFive;
