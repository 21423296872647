import logo from "./logo.svg";
import "./App.css";
// Import Css Here
import "./assets/scss/style.scss";
import "./assets/css/centermobile.css";
import Modal from "react-bootstrap/Modal";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCodesandbox } from "react-icons/fi";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./pages/HeaderTopBar";
import SEO from "./pages/SEO";
import HeaderOne from "./pages/HeaderOne";
import Separator from "./pages/Separator";
import SectionTitle from "./pages/SectionTitle";
import SlpitOne from "./pages/SlpitOne";
import SlipFour from "./pages/SlipFour";
import Wrestlers from "./pages/Wrestlers";
import Utility from "./pages/Utility";
import RoadMap2 from "./pages/RoadMap2";
import Team2 from "./pages/Team2";
import CalltoActionFive from "./pages/CalltoActionFive";
import Socials from "./pages/Socials";
import CalltoActionTwo from "./pages/CalltoActionTwo";
import Counter from "./pages/Counter";
import Utility2 from "./pages/Utility2";
import { BsDiscord } from "react-icons/bs";
import Footer from "./pages/Footer";
import { useContext, useEffect } from "react";
import { Admins, Verified, Wallet } from "./App";
import Axios from "axios";
import { useState } from "react";
import CalltoActionOne from "./pages/CalltoActionOne";
import { Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {
  Table as T,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import ServiceFour from "./pages/ServiceFour";
import ServiceThree from "./pages/ServiceThree";
import ServiceOne from "./pages/ServiceOne";
import Calendar from "react-calendar";
import Time from "./pages/Time.js";
function AdminPanel() {
  const [wallet, setWallet] = useContext(Wallet);
  const [verified, setVerified] = useContext(Verified);
  const [url, setUrl] = useState("https://verifier.brunowalter.repl.co");
  const [codes, setCodes] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [checkConnection, setCheckConnection] = useState([]);
  const [admins, setAdmins] = useContext(Admins);
  const [adminAdd, setAdminAdd] = useState("");
  const [adminAddCheck, setAdminAddCheck] = useState(false);
  const [adminDelete, setAdminDelete] = useState("");
  const [loading, setLoading] = useState(true);
  const [modifyDate, setModifyDate] = useState("");
  const [modifyImg, setModifyImg] = useState("");
  const [modifyNft, setModifyNft] = useState("");
  const [modEmail, setModEmail] = useState("");

  const [show, setShow] = useState(false);
  const [modifyChange, setModifyChange] = useState("");
  const [modifyText, setModifyText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [dateForB, setDateForB] = useState(new Date());
  const [showTime, setShowTime] = useState(false);

  useEffect(() => {
    if (adminAddCheck == true) {
      setTimeout(() => setAdminAddCheck(false), 3000);
    }
  }, [adminAddCheck]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  //adding new wallet to db
  const add = (a, b) => {
    Axios.post(url + "/api/add", {
      name: a,
      code: "test",
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Added";
      }
    });
  };

  const approve = (a) => {
    Axios.post(url + "/api/approve", {
      mint_address: a,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Added";
      }
    });
  };

  const deleteBooking = (a) => {
    Axios.post(url + "/api/deletebooking", {
      mint_address: a,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Added";
      }
    });
  };

  const addAdmin = (a) => {
    Axios.post(url + "/api/addadmin", {
      wallet: adminAdd,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Added";
      }
    });
  };

  const deleteAdmin = (a) => {
    Axios.post(url + "/api/deleteadmin", {
      wallet: adminDelete,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        return "Deleted";
      }
    });
  };

  const changeStatus = (a, b) => {
    Axios.post(url + "/api/changestatus", {
      status: a,
      nft: b,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        setModifyNft("");
        setModifyText("");
        return "Deleted";
      }
    });
  };

  const addinfo = (a) => {
    Axios.post(url + "/api/addinfo", {
      addinfo: modifyText != "" ? modifyText : "N/A",
      nft: modifyNft,
    }).then((response) => {
      if (response.data.message) {
        return alert(response.data.message);
      } else {
        setModifyNft("");
        setModifyText("");
        return "Deleted";
      }
    });
  };

  const modifyEmail = () => {
    Axios.post(url + "/api/modify", {
      modifyChange: modifyChange,
      modifyNft: modifyNft,
      modifyDateTime: modifyDate,
      modifyImg: modifyImg,
      modEmail: modEmail,
    }).then((response) => {
      if (response.data.message) {
        return console.log(response.data.message);
      } else {
        setModifyChange("");
        setModifyNft("");
        setModifyDate("");
        setModifyImg("");
        setModEmail("");
        console.log("mesage sent");
      }
    });
  };

  const approveEmail = (nft, date, image, email) => {
    console.log("this is the approval stuff");
    console.log(nft, date, image, email);
    Axios.post(url + "/api/approvals", {
      modifyNft: nft,
      modifyDateTime: date,
      modifyImg: image,
      modEmail: email,
    }).then((response) => {
      if (response.data.message) {
        return console.log(response.data.message);
      }
    });
  };

  //get all wallets
  useEffect(() => {
    const a = async () =>
      await Axios.get(url + "/api/get").then((response) => {
        setCodes(response.data.map((a) => a.name));
      });
    a();
  }, []);

  /*useEffect(() => {
    if (
      wallet != null &&
      wallet != "" &&
      wallet != undefined &&
      verified == true &&
      codes.length > 0
    ) {
      if (codes.includes(wallet) == false) {
        console.log("wallet " + wallet);
        console.log("verified: " + verified);
        console.log(codes);
        add(wallet);
      } else {
        console.log("did not add wallet" + wallet + verified);
      }
    }
  }, [codes, wallet, verified]);*/

  useEffect(() => {
    if (wallet == "" || wallet == null || wallet == undefined) {
      setVerified(false);
    }
  }, [wallet]);

  const changeLiveStatus = (a) => {
    Axios.post(url + "/api/changelivestatus", {
      site: "fightclub",
      status: a,
    }).then((response) => {
      alert("Changed Status of Site to: " + a);
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const a = async () =>
        await Axios.get(url + "/api/getbookings")
          .then((response, err) => {
            setLoading(false);
            if (response.data.length > 0) {
              setBookings(response.data);
            } else {
              setBookings([]);
            }
          })
          .catch((error) => {
            setCheckConnection([{ display: "none" }]);
          });
      a();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (admins.includes(wallet) == false) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  return (
    <>
      <SEO title="Fight Capital Admin Panel" />
      <Modal show={show} onHide={handleClose} style={{ color: "black" }}>
        <Modal.Header>
          <Modal.Title style={{ color: "black" }}>
            Please Specify What You would like modified
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Please Specify Modification</Form.Label>

              <Form.Control
                onChange={({ target }) => {
                  console.log(target.value);
                  setModifyChange(target.value);
                }}
                as="select"
                custom
              >
                <>
                  <option>Please Select Modification</option>
                  <option value="Date">Change Date</option>
                  <option value="Image">Change Image</option>
                  <option value="Date/Image">Change Date/Image</option>
                </>
              </Form.Control>
              <Form.Label htmlFor="inputPassword5">
                Additonal Information
              </Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                onChange={({ target }) => setModifyText(target.value)}
              />
              <Form.Text id="passwordHelpBlock" muted>
                Please insert any additional information required
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              changeStatus(modifyChange, modifyNft);
              modifyEmail();
              addinfo();
              handleClose();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <main className="page-wrapper" style={checkConnection}>
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h1 className="display-two">
                    Fight Capital <br /> Admin Panel <br />
                    {/*For{"  "}
                        <Typed
                          strings={["Freelancer.", "Developer.", "Startup."]}
                          typeSpeed={80}
                          backSpeed={5}
                          backDelay={1000}
                          loop
      />*/}
                  </h1>
                  <h3>EXCLUSIVE FULLY LICENSED PROS</h3>
                  <h4 style={{ color: "orange" }}>
                    THE BIGGEST WRESTLING PERSONALITIES
                  </h4>
                </div>
              </div>
              <div className="col-lg-4 order-1 order-lg-2">
                <div className="thumbnail">
                  <img src="./images/FC/955.png" alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <div style={{ width: "80%", padding: "auto", margin: "auto" }}>
          <h1 style={{ textAlign: "center" }}>Booking Stats</h1>
          <ServiceOne
            textAlign="text-center"
            serviceStyle="service__style--1 bg-color-blackest radius mt--25"
            book={bookings}
          />
        </div>
        <div
          className="rn-service-area rn-section-gap"
          style={{ padding: "1rem" }}
        >
          <div className="container">
            <div className="rwt-advance-tab-area rn-section-gap">
              <div className="container">
                {bookings.filter((a) => a.status == "pending").length > 0 && (
                  <div className="row mb--40">
                    <div className="col-lg-12">
                      <SectionTitle
                        textAlign="text-center"
                        radiusRounded=""
                        title="User Submissions (UnApproved)"
                      />
                    </div>
                  </div>
                )}
                <Row xs={1} md={2} className="g-4">
                  {loading == true ? (
                    <Card
                      border="secondary"
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        margin: "1rem",
                      }}
                    >
                      <Card.Header>
                        <strong>Booking #</strong>
                        <Spinner animation="border" variant="light" />
                      </Card.Header>
                      <Card.Body>
                        <Card.Title style={{ margin: "0" }}>Email</Card.Title>
                        <Card.Text style={{ margin: "0" }}>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title style={{ margin: "0" }}>
                          Image Url
                        </Card.Title>
                        <Card.Text style={{ margin: "0" }}>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title style={{ margin: "0" }}>
                          Date/Time
                        </Card.Title>
                        <Card.Text style={{ margin: "0" }}>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title style={{ margin: "0" }}>Nft</Card.Title>
                        <Card.Text style={{ margin: "0" }}>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title style={{ margin: "0" }}>Wallet</Card.Title>
                        <Card.Text style={{ margin: "0" }}>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title>Approve</Card.Title>
                      </Card.Body>
                    </Card>
                  ) : (
                    bookings.map(
                      (a) =>
                        a.approved == "false" &&
                        a.status == "pending" && (
                          <Col>
                            <Card
                              border="secondary"
                              style={{
                                backgroundColor: "black",
                                color: "white",
                                margin: "1rem",
                              }}
                            >
                              <Card.Header>
                                <strong>Booking # {a.id}</strong>
                              </Card.Header>
                              <Card.Body>
                                <Card.Title style={{ margin: "0" }}>
                                  Email
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.email}
                                </Card.Text>
                                <Card.Title style={{ margin: "0" }}>
                                  Image Url
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.imageUrl}
                                </Card.Text>
                                <Card.Title style={{ margin: "0" }}>
                                  Date/Time
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.dateTime}
                                </Card.Text>
                                <Card.Title style={{ margin: "0" }}>
                                  Nft
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.nft}
                                </Card.Text>
                                <Card.Title style={{ margin: "0" }}>
                                  Wallet
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.wallet}
                                </Card.Text>
                                <Card.Title>Approve</Card.Title>
                                <Card.Text>
                                  {a.approved == "false" ? (
                                    <>
                                      <Button
                                        variant="success"
                                        onClick={() => {
                                          changeStatus("Approved", a.nft);
                                          approve(a.nft);
                                          approveEmail(
                                            a.nft,
                                            a.dateTime,
                                            a.imageUrl,
                                            a.email
                                          );
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Approve
                                      </Button>
                                      <br />
                                      <Button
                                        variant="warning"
                                        onClick={() => {
                                          setModifyNft(a.nft);
                                          console.log("this is the date time");
                                          console.log(a.dateTime);
                                          setModifyDate(a.dateTime);
                                          setModifyImg(a.imageUrl);
                                          setModEmail(a.email);
                                          handleShow();
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Modify
                                      </Button>
                                      <br />
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          changeStatus("Email", a.nft)
                                        }
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        See Email
                                      </Button>
                                      <br />
                                      <Button
                                        variant="danger"
                                        onClick={() => deleteBooking(a.nft)}
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </>
                                  ) : (
                                    a.approved
                                  )}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                    )
                  )}
                </Row>

                {/*<T>
                  <Thead>
                    <Tr>
                      <Th>Email</Th>
                      <Th>Image UrL</Th>
                      <Th>Date/Time</Th>
                      <Th>NFT/Wallet</Th>
                      <Th>Approve</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {loading == true ? (
                      <Tr>
                        <Td>
                          <h4>Loading</h4>
                        </Td>
                      </Tr>
                    ) : (
                      bookings.length > 0 &&
                      bookings.map(
                        (a) =>
                          a.approved == "false" &&
                          a.status == "pending" && (
                            <Tr>
                              <Td>
                                <a href={a.email}>{a.email}</a>
                              </Td>
                              <Td>
                                <a href={a.imageUrl}>{a.imageUrl}</a>
                              </Td>
                              <Td>{a.dateTime}</Td>
                              <Td>
                                {a.nft}
                                <br />
                                {a.wallet}
                              </Td>
                              <Td>
                                {a.approved == "false" ? (
                                  <>
                                    <Button
                                      variant="success"
                                      onClick={() => {
                                        changeStatus("Approved", a.nft);
                                        approve(a.nft);
                                      }}
                                      style={{ width: "100%" }}
                                    >
                                      Approve
                                    </Button>
                                    <br />
                                    <Button
                                      variant="warning"
                                      onClick={() => {
                                        setModifyNft(a.nft);
                                        handleShow();
                                      }}
                                      style={{ width: "100%", height: "33%" }}
                                    >
                                      Modify
                                    </Button>
                                    <br />
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        changeStatus("Email", a.nft)
                                      }
                                      style={{ width: "100%", height: "33%" }}
                                    >
                                      See Email
                                    </Button>
                                    <br />
                                    <Button
                                      variant="danger"
                                      onClick={() => deleteBooking(a.nft)}
                                      style={{ width: "100%", height: "33%" }}
                                    >
                                      Delete
                                    </Button>
                                  </>
                                ) : (
                                  a.approved
                                )}
                              </Td>
                            </Tr>
                          )
                      )
                    )}
                  </Tbody>
                </T>*/}
                {/*UTILITY STUFF */}

                {/* <Utility />*/}
              </div>
            </div>

            <div className="rwt-advance-tab-area rn-section-gap">
              <div className="container">
                {bookings.filter(
                  (a) => a.status != "pending" && a.approved != "true"
                ).length > 0 && (
                  <div className="row mb--40">
                    <div className="col-lg-12">
                      <SectionTitle
                        textAlign="text-center"
                        radiusRounded=""
                        title="User Submissions (Pending Changes)"
                      />
                    </div>
                  </div>
                )}
                <Row xs={1} md={2} className="g-4">
                  {loading == true ? (
                    <Card
                      border="secondary"
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        margin: "1rem",
                      }}
                    >
                      <Card.Header>
                        <strong>Booking #</strong>
                        <Spinner animation="border" variant="light" />
                      </Card.Header>
                      <Card.Body>
                        <Card.Title style={{ margin: "0" }}>Email</Card.Title>
                        <Card.Text style={{ margin: "0" }}>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title style={{ margin: "0" }}>
                          Image Url
                        </Card.Title>
                        <Card.Text style={{ margin: "0" }}>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title style={{ margin: "0" }}>
                          Date/Time
                        </Card.Title>
                        <Card.Text style={{ margin: "0" }}>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title style={{ margin: "0" }}>Nft</Card.Title>
                        <Card.Text style={{ margin: "0" }}>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title style={{ margin: "0" }}>Wallet</Card.Title>
                        <Card.Text style={{ margin: "0" }}>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title style={{ margin: "0" }}>Approve</Card.Title>
                      </Card.Body>
                    </Card>
                  ) : (
                    bookings.length > 0 &&
                    bookings.map(
                      (a) =>
                        a.approved == "false" &&
                        a.status != "pending" && (
                          <Col>
                            <Card
                              border="secondary"
                              style={{
                                backgroundColor: "black",
                                color: "white",
                                margin: "1rem",
                              }}
                            >
                              <Card.Header>
                                <strong>Booking # {a.id}</strong>
                              </Card.Header>
                              <Card.Body>
                                <Card.Title style={{ margin: "0" }}>
                                  Email
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.email}
                                </Card.Text>

                                <Card.Title style={{ margin: "0" }}>
                                  Date/Time
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.dateTime}
                                </Card.Text>
                                <Card.Title style={{ margin: "0" }}>
                                  Image Url
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.imageUrl}
                                </Card.Text>
                                <Card.Title style={{ margin: "0" }}>
                                  Nft
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.nft}
                                </Card.Text>

                                <Card.Title style={{ margin: "0" }}>
                                  Wallet
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.wallet}
                                </Card.Text>
                                <Card.Title style={{ margin: "0" }}>
                                  Pending Change
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.status}
                                </Card.Text>
                                <Card.Title>Approve</Card.Title>
                                <Card.Text>
                                  {a.approved == "false" ? (
                                    <>
                                      <Button
                                        variant="success"
                                        onClick={() => {
                                          changeStatus("Approved", a.nft);
                                          approve(a.nft);
                                          approveEmail(
                                            a.nft,
                                            a.dateTime,
                                            a.imageUrl,
                                            a.email
                                          );
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Approve
                                      </Button>
                                      <br />
                                      <Button
                                        variant="warning"
                                        onClick={() => {
                                          setModifyNft(a.nft);
                                          setModifyDate(a.dateTime);
                                          setModifyImg(a.imageUrl);
                                          setModEmail(a.email);
                                          handleShow();
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Modify
                                      </Button>
                                      <br />
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          changeStatus("Email", a.nft)
                                        }
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        See Email
                                      </Button>
                                      <br />
                                      <Button
                                        variant="danger"
                                        onClick={() => deleteBooking(a.nft)}
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </>
                                  ) : (
                                    a.approved
                                  )}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                    )
                  )}
                </Row>

                {/*   <T>
                  <Thead>
                    <Tr>
                      <Th>Email/Image UrL</Th>
                      <Th>
                        Date/Time
                        <br />
                        NFT/Wallet
                      </Th>
                      <Th>Pending Change</Th>
                      <Th>Updated?</Th>
                      <Th>Approve</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {loading == true ? (
                      <Tr>
                        <Td>
                          <h4>Loading</h4>
                        </Td>
                      </Tr>
                    ) : (
                      bookings.length > 0 &&
                      bookings.map(
                        (a) =>
                          a.approved == "false" &&
                          a.status != "pending" && (
                            <Tr>
                              <Td>
                                <a href={a.email}>{a.email}</a>
                                <br />
                                <a href={a.imageUrl}>{a.imageUrl}</a>
                              </Td>

                              <Td>
                                {a.dateTime}
                                <br /> {a.nft}
                                <br />
                                {a.wallet}
                              </Td>
                              <Td>{a.status}</Td>
                              <Td>{a.updated}</Td>

                              <Td>
                                <Button
                                  variant="success"
                                  onClick={() => {
                                    changeStatus("Approved", a.nft);
                                    approve(a.nft);
                                  }}
                                  style={{ width: "100%" }}
                                >
                                  Approve
                                </Button>
                                <br />
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    changeStatus("See Email", a.nft)
                                  }
                                  style={{ width: "100%" }}
                                >
                                  See Email
                                </Button>
                                <br />
                                <Button
                                  variant="danger"
                                  onClick={() => deleteBooking(a.nft)}
                                  style={{ width: "100%", height: "100%" }}
                                >
                                  Delete Booking
                                </Button>
                              </Td>
                            </Tr>
                          )
                      )
                    )}
                  </Tbody>
                                </T>*/}

                {/*UTILITY STUFF */}

                {/* <Utility />*/}
              </div>
            </div>

            <div className="rwt-advance-tab-area rn-section-gap">
              <div className="container">
                {bookings.filter(
                  (a) => a.status != "pending" && a.approved == "true"
                ).length > 0 && (
                  <div className="row mb--40">
                    <div className="col-lg-12">
                      <SectionTitle
                        textAlign="text-center"
                        radiusRounded=""
                        title="User Submissions (Approved)"
                      />
                    </div>
                  </div>
                )}
                <Row xs={1} md={2} className="g-4">
                  {loading == true ? (
                    <Card
                      border="secondary"
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        margin: "1rem",
                      }}
                    >
                      <Card.Header>
                        <strong>Booking #</strong>
                        <Spinner animation="border" variant="light" />
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>Email</Card.Title>
                        <Card.Text>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title>Image Url</Card.Title>
                        <Card.Text>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title>Date/Time</Card.Title>
                        <Card.Text>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title>Nft</Card.Title>
                        <Card.Text>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                        <Card.Title>Wallet</Card.Title>
                        <Card.Text>
                          <Spinner animation="border" variant="light" />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  ) : (
                    bookings.length > 0 &&
                    bookings.map(
                      (a) =>
                        a.approved == "true" &&
                        a.status != "pending" && (
                          <Col>
                            <Card
                              border="secondary"
                              style={{
                                backgroundColor: "black",
                                color: "white",
                                margin: "1rem",
                              }}
                            >
                              <Card.Header>
                                <strong>Booking # {a.id}</strong>
                              </Card.Header>
                              <Card.Body>
                                <Card.Title style={{ margin: "0" }}>
                                  Email
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.email}
                                </Card.Text>

                                <Card.Title style={{ margin: "0" }}>
                                  Date/Time
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.dateTime}
                                </Card.Text>
                                <Card.Title style={{ margin: "0" }}>
                                  Image Url
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.imageUrl}
                                </Card.Text>
                                <Card.Title style={{ margin: "0" }}>
                                  Nft
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.nft}
                                </Card.Text>

                                <Card.Title style={{ margin: "0" }}>
                                  Wallet
                                </Card.Title>
                                <Card.Text style={{ margin: "0" }}>
                                  {a.wallet}
                                </Card.Text>

                                <Card.Text>
                                  {a.approved == "true" ? (
                                    <>
                                      <Button
                                        variant="danger"
                                        onClick={() => deleteBooking(a.nft)}
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </>
                                  ) : (
                                    a.approved
                                  )}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                    )
                  )}
                </Row>
                {/*<T>
                  <Thead>
                    <Tr>
                      <Th>Email</Th>
                      <Th>Image UrL</Th>
                      <Th>Date/Time</Th>
                      <Th>NFT/Wallet</Th>
                      <Th>Approved</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {loading == true ? (
                      <Tr>
                        <Td>
                          <h4>Loading</h4>
                        </Td>
                      </Tr>
                    ) : (
                      bookings.length > 0 &&
                      bookings.map(
                        (a) =>
                          a.approved == "true" && (
                            <Tr>
                              <Td>
                                <a href={a.email}>{a.email}</a>
                              </Td>
                              <Td>
                                <a href={a.imageUrl}>{a.imageUrl}</a>
                              </Td>
                              <Td>{a.dateTime}</Td>
                              <Td>
                                {a.nft}
                                <br />
                                {a.wallet}
                              </Td>
                              <Td>
                                <Button
                                  variant="danger"
                                  onClick={() => deleteBooking(a.nft)}
                                  style={{ width: "100%", height: "100%" }}
                                >
                                  Delete Booking
                                </Button>
                              </Td>
                            </Tr>
                          )
                      )
                    )}
                  </Tbody>
                          </T>*/}

                {/*UTILITY STUFF */}

                {/* <Utility />*/}
              </div>
            </div>

            {/* End Elements Area  */}
            <Separator />
          </div>
        </div>

        {/* Start Elements Area  */}

        <Footer />

        {/* End Elements Area  */}
      </main>
    </>
  );
}

export default AdminPanel;
