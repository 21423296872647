import React, { useContext } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Admins, Verified, Wallet } from "../App";
import { CanvasShow } from "./HeaderOne";

const Nav = () => {
  const [verified, setVerified] = useContext(Verified);
  const [wallet, setWallet] = useContext(Wallet);
  const [admins, setAdmins] = useContext(Admins);
  const [canvasShow, setCanvasShow] = useContext(CanvasShow);

  return (
    <ul className="mainmenu">
      <li>
        <Link to="/" onClick={() => setCanvasShow(false)}>
          Home
        </Link>
      </li>
      <li>
        <Link to={{ pathname: "https://fightcapital.io/mint" }} target="_blank">
          Mint
        </Link>
      </li>
      {/*verified && (
        <li>
          <Link to="/holders" onClick={() => setCanvasShow(false)}>
            Verified Portal
          </Link>
        </li>
      )*/}
      {verified && (
        <li>
          <Link to="/fightclub" onClick={() => setCanvasShow(false)}>
            Fight Club
          </Link>
        </li>
      )}

      {admins.includes(wallet) == true && verified == true && (
        <li>
          <Link to="/admin" onClick={() => setCanvasShow(false)}>
            Admin Panel
          </Link>
        </li>
      )}
    </ul>
  );
};
export default Nav;
