import React, { useEffect } from "react";
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceOne = ({ textAlign, serviceStyle, book }) => {
  const ServiceList = [
    {
      icon: <FiActivity />,
      title: "Unapproved",
      description:
        book.length < 1
          ? "Loading"
          : book?.filter((a) => a.status == "pending")?.length,
    },
    {
      icon: <FiCast />,
      title: "Pending Changes",
      description:
        book.length < 1
          ? "Loading"
          : book?.filter((a) => a.status != "pending" && a.approved == "false")
              ?.length,
    },
    {
      icon: <FiMap />,
      title: "Approved",
      description:
        book.length < 1
          ? "Loading"
          : book?.filter((a) => a.approved == "true")?.length,
    },
  ];

  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <div className={`service ${serviceStyle} ${textAlign}`}>
            <div className="icon">{val.icon}</div>
            <div className="content">
              <h4 className="title w-600">
                <Link
                  to="#"
                  dangerouslySetInnerHTML={{ __html: val.title }}
                ></Link>
              </h4>
              <h4
                style={{ color: "white" }}
                dangerouslySetInnerHTML={{ __html: val.description }}
              ></h4>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
