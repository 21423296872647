import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const SectionTitle = ({
  subtitle,
  title,
  description,
  textAlign,
  radiusRounded,
}) => {
  return (
    <div className={`section-title ${textAlign}`}>
      <h2
        className="title w-600 mb--20"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h2>

      <p
        className="description b1"
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
    </div>
  );
};
export default SectionTitle;
