import {
  useState,
  useRef,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "./logo/Logo";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import { setVisible } from "@solana/wallet-adapter-react-ui";
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import Axios from "axios";
import {
  Admins,
  HoldersCheck,
  Mini,
  NftList,
  Url,
  UrlCheckCodes,
  UrlCodes,
  Verified,
  Wallet,
} from "../App";

require("@solana/wallet-adapter-react-ui/styles.css");
export const CanvasShow = createContext();

const HeaderOne = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;
  const { clientHeight } = ref;
  const [wallet, setWallet] = useContext(Wallet);
  const [verified, setVerified] = useContext(Verified);
  const [mini, setMini] = useContext(Mini);
  const [lis2, setLis2] = useState([]);
  const [nftList, setNftList] = useContext(NftList);
  const [urlCodes, setUrlCodes] = useContext(UrlCodes);
  const [urlCheckCodes, setUrlCheckCodes] = useState([]);
  const [urlCheck, setUrlCheck] = useState(false);
  const [url, setUrl] = useContext(Url);
  const [mintAddCheck, setMintAddCheck] = useState([]);
  const [nftList2, setNftList2] = useState([]);
  const [holdersCheck, setHoldersCheck] = useContext(HoldersCheck);
  const [admins, setAdmins] = useContext(Admins);

  //disabling console log

  const checkChange = (value) => {
    setCheck(value);
  };

  const solNetwork = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork]);
  // initialise all the wallets you want to use
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ solNetwork }),
      new TorusWalletAdapter(),

      new SolletExtensionWalletAdapter(),
      new SolletWalletAdapter(),
    ],
    [solNetwork]
  );

  const MyComponent = () => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();

    useEffect(() => {
      //console.log("connection");
      //console.log(connection);
      //console.log("publicKey");
      setWallet(publicKey?.toBase58());
      //console.log(publicKey?.toBase58());
    }, [connection, publicKey, sendTransaction]);
    return (
      <div>
        <WalletMultiButton />
      </div>
    );
  };

  //once Wallet connects
  //checking mint data
  useEffect(() => {
    if (wallet != "" && wallet != undefined && wallet != null) {
      getMintData();
      setVerified(false);
      setUrlCheckCodes([]);
      console.log("mint data gotten");
    } else {
      setVerified(false);
    }
  }, [wallet]);

  //get mint data set mini
  //get Wallet data of owner of Wallet
  const getMintData = async () => {
    await Axios.post(
      "https://nameless-muddy-hill.solana-mainnet.quiknode.pro",
      {
        jsonrpc: "2.0",
        id: 1,
        method: "getTokenAccountsByOwner",
        params: [
          wallet,
          {
            programId: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA",
          },
          {
            encoding: "jsonParsed",
          },
        ],
      }
    ).then((response) => {
      // console.log("this is the response");
      // console.log(response.data.result.value);
      /* console.log(
        response.data.result.value.map((a) => a.account.data.parsed.info.mint)
      );*/
      console.log("This is to check for inventory");

      setMini(
        response.data.result.value
          .map((a) =>
            a.account.data.parsed.info.tokenAmount.amount != "0"
              ? a.account.data.parsed.info.mint
              : ""
          )
          .filter((a) => a != "")
      );
    });
  };

  //get the metadata for mini
  useEffect(() => {
    if (mini.length > 0 && mini.length <= 100) {
      //getMetadata();
      console.log("getting mini data");
      console.log(mini);
      getMetadata(mini);
    } else if (mini.length > 100 && lis2.length > 0) {
      console.log("getting lis2 data");
      lis2.map((a) => getMetadata(a));
    }
  }, [mini, lis2]);

  //set list 2 for greater than 100
  useEffect(() => {
    let b = Math.ceil(mini.length / 100);
    let c = 0;
    let d = 100;
    let lists = [];
    while (b > 0) {
      lists = [...lists, mini.slice(c, d)];
      b -= 1;
      c += 100;
      d += 100;
    }
    setLis2(lists);
  }, [mini]);

  const urlKey = [
    "c2aec335-12ad-4e7e-a425-22114052de00",
    "aeab4405-ce00-4b6b-817e-cfe62e303de5",
    "b710849f-e496-4b45-8932-e065b0ec0b4a",
    "58e5a6a8-7384-4482-928f-cc9440de8eca",
    "25bbd8ae-0855-41b2-a9c3-183a736b1bcc",
    "06ca8b43-ac6b-4195-81b9-81b7da18d5a9",
    "2dc7336e-338c-4cb6-9528-07e5a946abb9",
    "14c0d9f5-6397-4413-b75a-614d072f7763",
  ];
  //getting the metadata for mini and verifying
  const urlForNft = `https://api.helius.xyz/v0/tokens/metadata?api-key=${
    urlKey[Math.floor(Math.random() * urlKey.length)]
  }`;

  const getMetadata = async (a) => {
    const { data } = await Axios.post(urlForNft, { mintAccounts: a });
    //console.log(data.length);
    if (data.length > 0) {
      console.log("this is the data");
      console.log(data);
      data.map(
        (a, index) =>
          a.onChainData?.updateAuthority.includes(
            "FCNAWFRkJMFFBBz9hyoQgk8yEy1SHVTmn1rHnmuSPhxQ"
          ) && setVerified(true)
      );

      setHoldersCheck((prev) => [
        ...prev,
        data
          .map((a) =>
            a.onChainData?.updateAuthority.includes(
              "FCNAWFRkJMFFBBz9hyoQgk8yEy1SHVTmn1rHnmuSPhxQ"
            ) && a.offChainData?.symbol == "FCAP-A"
              ? a.mint
              : ""
          )
          .filter((a) => a != ""),
      ]);

      setNftList(
        data
          .map((a) =>
            a.onChainData?.updateAuthority.includes(
              "FCNAWFRkJMFFBBz9hyoQgk8yEy1SHVTmn1rHnmuSPhxQ"
            )
              ? {
                  mintAddress: a.mint,
                  name: a.offChainData?.name,
                  image: a.offChainData?.image,
                }
              : ""
          )
          .filter((a) => a != "")
      );
      setNftList2((a) => [
        ...a,
        data
          .map((a) =>
            a.onChainData?.updateAuthority.includes(
              "FCNAWFRkJMFFBBz9hyoQgk8yEy1SHVTmn1rHnmuSPhxQ"
            )
              ? {
                  mintAddress: a.mint,
                  name: a.offChainData?.name,
                  image: a.offChainData?.image,
                }
              : ""
          )
          .filter((a) => a != ""),
      ]);
    } else {
      alert("No NFTs Found");
    }
  };

  useEffect(() => {
    const a = async () =>
      await Axios.get(url + "/api/get").then((response) => {
        console.log("this is the get");
        console.log(response.data);
        setUrlCheckCodes(
          response.data.map((a) => a.mint_address).filter((a) => a != null)
        );
      });
    if (verified) {
      a();
    }
  }, [verified]);

  useEffect(() => {
    /*console.log("nft list");
    console.log(nftList);
    console.log("Url check codes");
    console.log(urlCheckCodes);*/
    if (nftList.length > 0 && urlCheckCodes.length > 0) {
      nftList.map((a) => console.log(urlCheckCodes.includes(a.mintAddress)));
      nftList.map((a) =>
        urlCheckCodes.includes(a.mintAddress) == false && verified == true
          ? addFull(wallet, a.mintAddress, a.name, a.image)
          : urlCheckCodes.includes(a.mintAddress) == true &&
            verified == true &&
            wallet != null &&
            wallet != "" &&
            wallet != undefined
          ? checkWallet(a.mintAddress)
          : console.log("You are not verified")
      );

      console.log("gonna make it false");
    } else {
      console.log("something is not true");
    }
  }, [nftList, verified, urlCheckCodes]);

  const checkWallet = (a) => {
    Axios.post(url + "/api/checkwallet", {
      name: wallet,
      mint_address: a,
    }).then((response) => {
      if (response.data.message) {
        //updateMint(a)

        updateWallet(a);
      } else {
        return "Checked";
      }
    });
  };

  const updateWallet = (a) => {
    Axios.post(url + "/api/updatewallet", {
      name: wallet,
      mint_address: a,
    }).then((response) => {
      console.log("Updating Wallet");
      if (response.data.message) {
        console.log(response.data.message);
      } else {
        console.log("NFTs have been Updated");
        return "Checked";
      }
    });
  };

  const getVerification = async () => {
    await Axios.post(url + "/api/getverification", {
      name: wallet,
    }).then((response) => {
      if (response.data.length > 0) {
        setMintAddCheck(response.data.map((a) => a.mint_address));
      } else {
        console.log("there is no code");
      }
    });
  };

  useEffect(() => {
    if (mintAddCheck.length > 0 && nftList2.length > 0) {
      console.log("this is the nft list for checking shittt");

      console.log(nftList2.map((a, index) => a[index]));
    }
  }, [mintAddCheck, nftList2]);

  useEffect(() => {
    getVerification();
  }, []);
  //get mint addresses from the database for that specific wallet address. Check that each nft in the database is in the wallet. If an nft is not in the wallet but in database, delete wallet address from nft in database
  /*useEffect(() => {
    console.log("nft list");
    console.log(nftList);
    console.log("Url check codes");
    console.log(urlCheckCodes);
    if (nftList.length > 0 && urlCheckCodes.length > 0) {
      nftList.map((a) => console.log(urlCheckCodes.includes(a.mintAddress)));
      nftList.map((a) =>
        urlCheckCodes.includes(a.mintAddress) == false && verified == true
          ? addFull(wallet, a.mintAddress, a.name, a.image)
          : console.log("not adding. its already here.")
      );

      console.log("gonna make it false");
    } else {
      console.log("something is not true");
    }
  }, [nftList, verified, urlCheckCodes]);*/

  const addFull = (a, b, c, d) => {
    Axios.post(url + "/api/addfull", {
      name: a,
      code: "test",
      mintAddress: b,
      mintName: c,
      mintImage: d,
    }).then((response) => {
      if (response.data.message) {
        return console.log("Fill in Values");
      } else {
        return console.log("added");
      }
    });
  };

  /* useEffect(() => {
    if (
      verified != true &&
      wallet != "" &&
      wallet != undefined &&
      wallet != null
    ) {
      alert("No Fight Capital NFTs Found.");
    }
  }, [verified]);*/

  useEffect(() => {
    getMintData();
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const a = async () =>
        await Axios.get(url + "/api/getadmin").then((response, err) => {
          if (response.data.length > 0) {
            setAdmins(response.data.map((a) => a.wallet));
          } else {
            console.log("there are no admins");
          }
        });

      a();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets}>
          <WalletModalProvider>
            <CanvasShow.Provider value={[ofcanvasShow, setOffcanvasShow]}>
              <header
                ref={ref}
                className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}
              >
                <div className="container position-relative">
                  <div className="row align-items-center row--0">
                    <div className="col-lg-3 col-md-6 col-4">
                      <Logo
                        image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                        image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                      />
                    </div>
                    <div className="col-lg-9 col-md-6 col-8 position-static">
                      <div className="header-right">
                        <nav className="mainmenu-nav d-none d-lg-block">
                          <Nav />
                        </nav>

                        <div className="header-btn">
                          <MyComponent />
                        </div>
                        <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                          <div className="hamberger">
                            <span
                              className="hamberger-button"
                              onClick={onCanvasHandler}
                            >
                              <FiMenu />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
            </CanvasShow.Provider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </>
  );
};
export default HeaderOne;
