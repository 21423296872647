import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./HeaderTopBar";
import SEO from "./SEO";
import HeaderOne from "./HeaderOne";
import Separator from "./Separator";
import SectionTitle from "./SectionTitle";
import SlpitOne from "./SlpitOne";
import SlipFour from "./SlipFour";
import Wrestlers from "./Wrestlers";
import Utility from "./Utility";
import RoadMap2 from "./RoadMap2";

import { BsDiscord } from "react-icons/bs";

export default function Socials() {
  return (
    <div className="main-content">
      {/* Start Elements Area  */}
      <div className="rwt-social-area rn-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="Our Social Share"
                title="Our Socials"
                description=""
              />
            </div>
          </div>
          <div className="row mt--30">
            <div className="col-lg-12">
              <ul className="social-icon social-default">
                <li>
                  <Link
                    to={{ pathname: "https://twitter.com/fightcapitalnft" }}
                    target="_blank"
                  >
                    <FiTwitter />
                  </Link>
                </li>
                <li>
                  <Link
                    to={{ pathname: "https://instagram.com/fightcapitalnft" }}
                    target="_blank"
                  >
                    <FiInstagram />
                  </Link>
                </li>
                <li>
                  <Link
                    to={{ pathname: "https://discord.gg/fightcapital" }}
                    target="_blank"
                  >
                    <BsDiscord />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
